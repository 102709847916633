import { Link } from 'react-router-dom';
import { LinkProps } from '@helpers/Props';
import './ReactLink.css';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';

const ReactLink: React.FC<LinkProps> = ({
  className = '',
  label = '',
  to = '',
  content = '',
}) => {

  const { windowWidth } = useWindowWidthContext()
  return (
    <Link to={to} className={`nav-link ${windowWidth > 1200 ? 'letter-spacing-1px' : ''} text-upper clr-purple ${className}`}>
      {content}{label}
    </Link>
  );
};

export default ReactLink;