import React, { createContext, useContext, useState, useEffect } from 'react';
import { MarqueProps } from '@helpers/Props';
import { useQuery } from 'react-query';
import { apiUrl } from '@helpers/Helpers';
import useApiRequest from '@mbs-dev/api-request';

const MarquesContext = createContext<{
    marques: MarqueProps[];
}>({
    marques: []
});

export const useMarquesContext = () => useContext(MarquesContext);

export const MarquesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // const [marques, setMarques] = useState<Marque[]>([]);
    const { apiRequest } = useApiRequest()

    // const fetchData = async () => {
    //     try {
    //         const response = await apiRequest({
    //             route: 'marques.json',
    //             method: 'GET',
    //         });

    //         setMarques(response.data)
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    // useEffect(() => {
    //     fetchData();
    // }, [])

    const {
        data,
        isLoading: isFetching,
    } = useQuery<MarqueProps[], Error>(
        'marques',
        async () => {
            const response = await apiRequest({
                route: `${apiUrl}/marques.json`,
                method: 'GET',
            });

            return response.data;

        }
    );

    const marques = data || []

    return (
        <>
            <MarquesContext.Provider value={{ marques }}>
                {children}
            </MarquesContext.Provider>
        </>
    );
};
