import { PackSwiperProps } from "@helpers/Props";
import { PackCard, hooks } from "@helpers/Index";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './PackSwiper.css'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useWindowWidthContext } from "@contexts/WindowWidthContext";

const PackSwiper: React.FC<PackSwiperProps> = ({ items }) => {
    const { productIds, isProductIds } = hooks.useProductIds();
    const { windowWidth } = useWindowWidthContext()

    let screen = 3

    if (windowWidth < 1400 && windowWidth >= 1200) {
        screen = 3
    } else if (windowWidth < 1200 && windowWidth >= 992) {
        screen = 3
    }
    else if (windowWidth < 992 && windowWidth >= 768) {
        screen = 3
    } else if (windowWidth < 768 && windowWidth >= 578) {
        screen = 2
    } else if (windowWidth < 578) {
        screen = 1
    }



    return (
        <>
            <Swiper
                className="mySwiper pack-swiper"
                slidesPerView={screen}
                // centeredSlides
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{
                    clickable: true,
                }}
                // autoplay={false}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
            >
                {items.map((item: any) => (
                    <SwiperSlide key={item.id}>
                        <PackCard key={item.id} item={item} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default PackSwiper;