import { FC, useState } from 'react';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import { useCategoriesContext } from '@contexts/CategoriesContext';
import { useMarquesContext } from '@contexts/MarquesContext';
import { CategorieProps, MarqueProps } from '@helpers/Props';
import { Link, useLocation } from 'react-router-dom';
import { logo } from '@helpers/Index';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ReactLink from '../ReactLink/ReactLink';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './NavBarStyle.css'

const HomeNavbar: FC = () => {

  const { windowWidth } = useWindowWidthContext()
  const { categories } = useCategoriesContext()
  const { marques } = useMarquesContext()

  const location = useLocation();
  const currentPach = location.pathname

  return (
    <div className='w-100 shadow-2 py-2px'>
      <div className="container">
        <div className="row">
          <Navbar expand="lg" bg='#fff' className='ff-montserrat-reg fw-600 fs-15px'>
            <Navbar.Brand>
              <Link to={'/'}> <img
                alt='rose-bleu'
                src={logo}
                width="60"
                height="60"
                className={`${windowWidth > 1200 ? 'me-5rem' : 'me-2rem'} d-inline-block align-top home-brand-logo ms-10px p-3px `}
              /></Link>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <ReactLink to="/" content='Home' className={`${location.pathname === '/' ? 'active-link' : ''}  home-navbar-item ${windowWidth > 992 ? '' : 'ms-2rem'}`} />
                <ReactLink to="/boutique" content='Boutique' className={`${location.pathname.startsWith('/boutique') && location.pathname !== '/boutique/packs' ? 'active-link' : ''}  home-navbar-item ${windowWidth > 992 ? '' : 'ms-2rem'}`} />
                <ReactLink to="/boutique/packs" content='Packs' className={`${location.pathname === '/boutique/packs' ? 'active-link' : ''}  home-navbar-item ${windowWidth > 992 ? '' : 'ms-2rem'}`} />
                <ReactLink to="/about-us" content='a propos' className={`${location.pathname === '/about-us' ? 'active-link' : ''}  home-navbar-item ${windowWidth > 992 ? '' : 'ms-2rem'}`} />
                <ReactLink to="/" content='|' className={`home-navbar-item ${windowWidth > 1200 ? '' : 'd-none'}`} />

                <NavDropdown title="NOS MARQUES" id="marques-dropdown" className={`text-upper clr-purple home-navbar-item ${windowWidth > 992 ? '' : 'ms-2rem'}`}>
                  {marques.map((item: MarqueProps) => (
                    <Link key={item.id} to={`/boutique/marque/${item.slug}`} className={`nav-link letter-spacing-1px clr-dark fs-14px text-cap pt-0 text-start ${windowWidth > 992 ? '' : 'ps-2rem'}`} role="menuitem">{item.title}</Link>
                  ))
                  }
                </NavDropdown>

                {categories.map((cat: CategorieProps) => (
                  <NavDropdown key={cat.id} title={`${cat.title}`} id={cat.slug} className={`text-upper clr-purple home-navbar-item ${windowWidth > 992 ? '' : 'ms-2rem'}`}>
                    <Link to={`/boutique/categorie/${cat.slug}`} className="nav-link letter-spacing-1px clr-purple text-cap pt-0 text-center" role="menuitem">{cat.title}</Link>
                    <hr className='hr-tag m-0' />
                    {cat.subCategory.map((sub: CategorieProps) => (
                      <Link key={sub.id} to={`/boutique/categorie/${cat.slug}/${sub.slug}`} className={`nav-link letter-spacing-1px fs-14px pb-1 clr-dark text-cap ${windowWidth > 992 ? '' : 'ps-2rem'}`} role="menuitem">{sub.title}</Link>
                    ))
                    }
                  </NavDropdown>
                ))
                }

              </Nav>
            </Navbar.Collapse>
          </Navbar >
        </div>
      </div >
    </div>
  );
};

export default HomeNavbar;