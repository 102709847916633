import React, { createContext, useContext, useState, useEffect } from 'react';

interface OrderInfoProps {
    userEmail?: string;
    reference: string;
}

interface OrderInfoContextProps {
    orderInfo: OrderInfoProps;
    setOrderInfo: React.Dispatch<React.SetStateAction<OrderInfoProps>>;
}

const OrderInfoContext = createContext<OrderInfoContextProps>({
    orderInfo: { userEmail: undefined, reference: '' },
    setOrderInfo: () => { },
});

export const useOrderInfoContext = () => useContext(OrderInfoContext);

export const OrderInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [orderInfo, setOrderInfo] = useState<OrderInfoProps>({
        userEmail: undefined,
        reference: '',
    });

    return (
        <OrderInfoContext.Provider value={{ orderInfo, setOrderInfo }}>
            {children}
        </OrderInfoContext.Provider>
    );
};
