import { FC } from 'react'
import SubCategorySwiper from '@components/Carousel/SubCategorySwiper/SubCategorySwiper'
import { apiUrl, categorieImgUrl } from '@helpers/Helpers'
import { ProductProps, ProductsCategoriesCardProps } from '@helpers/Props'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import ProductSwiper from '@components/Carousel/ProductSwiper/ProductSwiper'
import useApiRequest from '@mbs-dev/api-request'

const ProductsCategoriesCard: FC<ProductsCategoriesCardProps> = ({ categorie }) => {
    const { windowWidth } = useWindowWidthContext()
    const { apiRequest } = useApiRequest()

    const {
        data,
        isLoading: isFetching,
    } = useQuery<ProductProps[], Error>(
        ['products', { categorie: categorie.id }],
        async ({ pageParam = 1 }) => {
            const response = await apiRequest({
                route: `${apiUrl}/products.json`,
                method: 'GET',
                params: {
                    disponibilite: 'enstock',
                    categorie: categorie.id,
                    itemsPerPage: 7,
                    page: pageParam,
                }
            });
            return response.data;
        },
    );

    const products = data || []

    return (
        <div className='products-categories-card w-100'>
            <div className="proc-card-image w-100 h20rem o-hidden d-flex-center radius-10px shadow-2">
                <Link to={`/boutique/categorie/${categorie.slug}`} className={`nav-link`}>
                    <img src={`${categorieImgUrl}/${categorie.image}`} alt={`${categorie.title}`} className='object-fit-cover w-100 h-100' />
                </Link>
            </div>
            <div className={`proc-card-subcategory d-flex-center ox-hidden mx-${windowWidth > 578 ? '4rem' : '1rem'}`}>
                <SubCategorySwiper subCategories={categorie.subCategory} categorie={categorie} />
            </div>

            <div className="proc-card-product d-flex-center">
                {!isFetching && <ProductSwiper items={products} itemType='product' />}
            </div>

        </div>
    )
}

export default ProductsCategoriesCard