import { FC } from 'react';
import Icons from '@helpers/IconsHelper';
import { formHelper } from '@helpers/Index';
import { emailSchema } from '@helpers/YupHelper';
import useApiRequest from '@mbs-dev/api-request';
import { ErrorNotify, FrCustomeErrorNorify, notify } from '@helpers/Toastify';
import './NewsEmail.css'
import { apiUrl } from '@helpers/Helpers';

const NewsEmail: FC = () => {
    const { apiRequest } = useApiRequest();

    const formik = formHelper.useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: emailSchema,
        onSubmit: async (values, { resetForm }) => {
            try {

                const checkResponse = await apiRequest({
                    route: `${apiUrl}/check-news-email/${values.email}`,
                    method: 'GET',
                    data: values
                })

                if (checkResponse.status === 200) {
                    if (checkResponse.data.found === false) {
                        const response = await apiRequest({
                            route: `${apiUrl}/news_emails`,
                            method: 'POST',
                            data: values
                        })

                        if (response.status === 201) {
                            notify('Vous avez été enregistré avec succès')
                            resetForm()
                        } else {
                            FrCustomeErrorNorify()
                            resetForm()
                        }
                    } else {
                        ErrorNotify('Vous avez déjà enregistré')
                        resetForm()
                    }
                } else {

                }

            } catch {
            }
        },
    });


    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="input-group">
                <formHelper.FormInput
                    label=""
                    spanValue="Email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    className={formik.touched.email && formik.errors.email ? "is-invalid" : ""}
                    touched={formik.touched}
                    errors={formik.errors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                />

                <div className="input-group-append">
                    <span className="input-group-textt">
                        <Icons.FaTelegramPlane className='clr-dark sendemail' />
                    </span>
                </div>
            </div>
        </form>
    );
};

export default NewsEmail;