import { FC, useEffect } from 'react'
import { EmblaOptionsType } from 'embla-carousel'
import { useSliderContext } from '@contexts/SliderContext'
import { useMarquesContext } from '@contexts/MarquesContext'
import CategoryProducts from '@components/ProductsCategories/ProductsCategories'
import MarqueSwiper from '@components/Carousel/MarqueSwiper/MarqueSwiper'
import NewProducts from '@components/NewProducts/NewProducts'
import Layout from '@layouts/Layout'
import PopularePacks from '@components/PopularePacks/PopularePacks'
import AOS from 'aos';
import EmblaCarousel from '@components/Carousel/EmblaCarousel/EmblaCarousel'
import SpecialOffer from '@components/SpecialOffer/SpecialOffer'
import BrandEmblaCarousel from '@components/Carousel/BrandEmblaCarousel/BrandEmblaCarousel'
import './Home.css'
import 'aos/dist/aos.css';
import '@components/Carousel/EmblaCarousel/EmblaCarousel.css'

const OPTIONS: EmblaOptionsType = { loop: true }

const Home: FC = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    const { sliders, isFetching } = useSliderContext();
    const { marques } = useMarquesContext()


    return (
        <Layout>
            <EmblaCarousel slides={sliders} options={OPTIONS} isFetch={isFetching} />

            <div data-aos="fade-up" data-aos-duration="3000">
                <SpecialOffer />
            </div>

            <div data-aos="fade-up" data-aos-duration="3000">
                <NewProducts />
            </div>

            <div data-aos="fade-up" data-aos-duration="3000">
                <CategoryProducts />
            </div>

            <div data-aos="fade-up" data-aos-duration="3000">
                <PopularePacks />
            </div>

            <div data-aos="fade-up" data-aos-duration="3000">
                <BrandEmblaCarousel marques={marques} options={OPTIONS} />
                {/* <MarqueSwiper marques={marques} /> */}
            </div>
        </Layout>
    )
}

export default Home
