import { FC, useState } from 'react';
import './ProductImagesGallery.css'


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { ProductImagesGalleryProps } from '@helpers/Props';
import { PMultiImgUrl, VMultiImgUrl } from '@helpers/Helpers';

const ProductImagesGallery: FC<ProductImagesGalleryProps> = ({ product, isVariant = false, index = 0 }) => {

  const [thumbsSwiper, setThumbsSwiper] = useState(null);


  if (!product) {
    return null;
  }

  return (

    <div className='gallery-parent h-100'>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        } as React.CSSProperties & any}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >

        {
          isVariant ?
            product.variant[index].variantImages.map((item: { image: any; }) => (
              <SwiperSlide>
                <img src={`${VMultiImgUrl}/${item.image}`} />
              </SwiperSlide>
            ))
            : product.productImages.map((item) => (
              <SwiperSlide>
                <img src={`${PMultiImgUrl}/${item.image}`} />
              </SwiperSlide>
            ))
        }


      </Swiper>
      {/* <Swiper
        onSwiper={() => setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {
          isVariant ?
            product.variant[index].variantImages.map((item: { image: any; }) => (
              <SwiperSlide>
                <img src={`${VMultiImgUrl}/${item.image}`} />
              </SwiperSlide>
            ))


            : product.productImages.map((item) => (
              <SwiperSlide>
                <img src={`${PMultiImgUrl}/${item.image}`} />
              </SwiperSlide>
            ))
        }
      </Swiper> */}
    </div>
  );
};

export default ProductImagesGallery;