import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { OfflineCheckoutGuard } from './RoutingGuard';
import { BeatLoader } from "react-spinners";
import { logo } from '@helpers/Index';
import DashContent from '@pages/Account/pages/UserDashboard/DashContent';
import EditAccount from '@pages/Account/pages/UserEditAccount/EditAccount';
import UserOrderDetails from '@pages/Account/pages/Orders/UserOrderDetails';
import UserReviews from '@pages/Account/pages/UserReviews/UserReviews';
import UserOrders from '@pages/Account/pages/Orders/UserOrders';
import EditAddress from '@pages/Account/pages/UserAdresse/EditAddress';
import CodePromo from '@pages/Account/pages/CodePromo/CodePromo';
import AdresseFacturation from '@pages/Account/pages/UserAdresse/pages/AdresseFacturation';
import AdresseLivraison from '@pages/Account/pages/UserAdresse/pages/AdresseLivraison';
import PackDetails from '@pages/PackDetails/PackDetails';
import Checkout from '@pages/Checkout/Checkout';
import OfflineOrder from '@pages/OfflineOrder/OfflineOrder';
import ShoppingCart from '@pages/ShoppingCart/ShoppingCart';
import OfflineCheckout from '@pages/Checkout/OfflineCheckout';
import Login from '@pages/Auth/Login';
import Register from '@pages/Auth/Register';
import ForgotPassword from '@pages/Auth/ForgotPassword';
import ResetPassword from '@pages/Auth/ResetPassword';
import Home from '@pages/Home/Home';
import TopHeader from '@components/TopHeader/TopHeader';
import AboutUs from '@pages/AboutUs/AboutUs';
import Boutique from '@pages/Boutique/Pages/Product/Boutique';
import BestSellerBoutique from '@pages/Boutique/Pages/BestSeller/BestSellerBoutique';
import PromotionBoutique from '@pages/Boutique/Pages/Promotion/PromotionBoutique';
import LatestBoutique from '@pages/Boutique/Pages/Latest/LatestBoutique';
import PackBoutique from '@pages/Boutique/Pages/Pack/PackBoutique';
import HomeNavbar from '@components/Navbar/HomeNavbar';
import ProductDetails from '@pages/ProductDetails/ProductDetails';
import CmiOkPage from '@pages/Payment/CmiOkPage';
import CmiFailPage from '@pages/Payment/CmiFailPage';
import Error404 from '@pages/Errors/Error404';

const Routing: React.FC = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Router>
      {loading ? (
        <div
          className="loader d-flex gap-3 justify-content-center flex-column align-items-center"
          style={{ width: "100%", height: "100vh" }}
        >
          <img src={logo} style={{ width: "12rem" }} alt="" />
          <BeatLoader color="rgb(12, 154, 211)" />
        </div>
      ) : (
        <>
          <Routes>

            {/* -------- Global */}
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Error404 />} />
            <Route path="/boutique" element={<Boutique />} />
            {/* <Route path="/product-details/:slug" element={<ProductDetails />} /> */}
            {/* <Route path="/produits/:slug" element={<ProductDetails />} /> */}
            <Route path="/offline-checkout" element={<OfflineCheckout />} />
            <Route path="/about-us" element={<AboutUs />} />

            {/* -------- Auth */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset_password/:token" element={<ResetPassword />} />

            {/* -------- Account */}
            <Route path="/my-account" element={<DashContent />} />
            <Route path="/my-account/edit-account" element={<EditAccount />} />
            <Route path="/my-account/orders" element={<UserOrders />} />
            <Route path="/my-account/orders/order-details/:id" element={<UserOrderDetails />} />
            <Route path="/my-account/edit-address" element={<EditAddress />} />
            <Route path="/my-account/code-promo" element={<CodePromo />} />
            <Route path="/my-account/reviews" element={<UserReviews />} />
            <Route path="/my-account/edit-address/facturation" element={<AdresseFacturation />} />
            <Route path="/my-account/edit-address/livraison" element={<AdresseLivraison />} />
            <Route path="/my-account/edit-address/livraison/:adresseId" element={<AdresseLivraison />} />
            <Route path="/my-account/edit-address/facturation/:adresseId" element={<AdresseFacturation />} />

            {/* -------- Boutique */}
            <Route path="/boutique" element={<Boutique />} />
            <Route path="/boutique/categorie/:categorie" element={<Boutique />} />
            <Route path="/boutique/categorie/:categorie/:subCategorie" element={<Boutique />} />
            <Route path="/boutique/marque/:marque" element={<Boutique />} />

            {/* -------- Best Seller */}
            <Route path="/boutique/best-seller" element={<BestSellerBoutique />} />
            <Route path="/boutique/best-seller/marque/:marque" element={<BestSellerBoutique />} />
            <Route path="/boutique/best-seller/categorie/:categorie" element={<BestSellerBoutique />} />
            <Route path="/boutique/best-seller/categorie/:categorie/:subCategorie" element={<BestSellerBoutique />} />

            {/* -------- Promotion */}
            <Route path="/boutique/promotion" element={<PromotionBoutique />} />
            <Route path="/boutique/promotion/marque/:marque" element={<PromotionBoutique />} />
            <Route path="/boutique/promotion/categorie/:categorie" element={<PromotionBoutique />} />
            <Route path="/boutique/promotion/categorie/:categorie/:subCategorie" element={<PromotionBoutique />} />

            {/* -------- Latest */}
            <Route path="/boutique/latest" element={<LatestBoutique />} />
            <Route path="/boutique/latest/marque/:marque" element={<LatestBoutique />} />
            <Route path="/boutique/latest/categorie/:categorie" element={<LatestBoutique />} />
            <Route path="/boutique/latest/categorie/:categorie/:subCategorie" element={<LatestBoutique />} />

            {/* -------- Pack */}
            <Route path="/boutique/packs" element={<PackBoutique />} />
            <Route path="/boutique/pack/:slug" element={<PackDetails />} />

            {/* -------- Product */}
            <Route path="/boutique/product/:slug" element={<ProductDetails />} />

            {/* -------- Cart/Order */}
            <Route path="/my-cart" element={<ShoppingCart />} />
            <Route path="/checkout" element={<Checkout />} />

            {/* -------- Offline Order */}
            <Route path="/check-offline-order/:uid" element={<OfflineOrder />} />

            <Route
              path="/offline-checkout"
              element={
                <OfflineCheckoutGuard>
                  <OfflineCheckout />
                </OfflineCheckoutGuard>
              }
            />

            {/* -------- Payment */}
            <Route path="/payments/cmi/ok" element={<CmiOkPage />} />
            <Route path="/payments/cmi/fail" element={<CmiFailPage />} />
            <Route path="/offline/payments/cmi/ok" element={<CmiOkPage />} />
            <Route path="/offline/payments/cmi/fail" element={<CmiFailPage />} />

          </Routes>
        </>
      )}
    </Router>
  );
};

export { Routing };