import { FC } from 'react'
import { BreadCrumbsProps } from '@helpers/Props'
import { Breadcrumbs } from '@mui/material'
import { emphasize, styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Icons from '@helpers/IconsHelper'
import './BreadCrumbs.css'

const BreadCrumbs: FC<BreadCrumbsProps> = ({ product }) => {

    const StyledBreadcrumb = styled(Chip)(({ theme }) => {
        const backgroundColor =
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[800]
        return {
            backgroundColor,
            height: theme.spacing(3),
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover, &:focus': {
                backgroundColor: emphasize(backgroundColor, 0.06),
            },
            '&:active': {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(backgroundColor, 0.12),
            },
        }
    }) as typeof Chip


    return (
        <Breadcrumbs aria-label="breadcrumb" className=''>
            <StyledBreadcrumb
                component="a"
                href="/"
                label="Accueil"
                icon={<Icons.AiFillHome fontSize="small" />}
            />
            <StyledBreadcrumb
                component="a"
                href={`/boutique/categorie/${product.categorie[0].slug}`}
                label={`${product.categorie[0].title}`}
            />
            {
                product.categorie[1] ?
                    <StyledBreadcrumb
                        component="a"
                        href={`/boutique/categorie/${product.categorie[0].slug}/${product.categorie[1]?.slug}`}
                        label={`${product.categorie[1]?.title}`}
                    />
                    :
                    null
            }

        </Breadcrumbs>
    )
}

export default BreadCrumbs
