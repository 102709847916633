import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import UserDashboard from '../UserDashboard/UserDashboard';
import { AuthUserProps, OrderProps } from '@helpers/Props';
import useApiRequest from '@mbs-dev/api-request';
import { Helmet } from 'react-helmet-async';
import { FrCustomeErrorNorify } from '@helpers/Toastify';
import { apiUrl, uploadUrl} from '@helpers/Helpers';
import AnimatedLoading from '@components/Loading/AnimatedLoading';
import './OrdersStyle.css'


const Content: React.FC = () => {

    const [orders, setOrders] = useState<OrderProps[]>([]);
    const { apiRequest } = useApiRequest();
    const [user, setUser] = useState<AuthUserProps | null>(null);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { state } = useLocation()

    useEffect(() => {
        const storedUser = localStorage.getItem('mbs_user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);


    const fetchData = useCallback(async () => {
        try {
            if (user?.id) {
                setIsLoading(true)
                const response = await apiRequest({
                    route: `${apiUrl}/orders.json?id=${id}`,
                    method: 'GET',
                    requiresAuth: true,
                    token: `${localStorage.getItem('mbs_user_token')}`
                });

                if (response.status === 200) {
                    setOrders(response.data)
                    setIsLoading(false)
                } else {
                    FrCustomeErrorNorify()
                    setIsLoading(false)
                }
            }

        } catch (error) {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    }, [user?.id, apiRequest]);


    useEffect(() => {
        if (state) {
            setOrders(state)
        } else {
            fetchData();
        }
    }, [user?.id])

    // let filteredData = orders.filter((item) => item.id === Number(id));

    return (
        <>
            <Helmet>
                <title>Détails de commande</title>
            </Helmet>
            <div className="col-12 col-lg-7 col-md-7 col-xl-8 col-xxl-9 mbs-ms-4 newold">

                {

                    isLoading ?
                        <AnimatedLoading label='les details de commande' className='col-12' />
                        :
                        <div className="account-order ms-4 mbs-me-3">

                            {
                                orders.length > 0 ?

                                    <div className="order-content edit-form">

                                        <table className='table align-middle gs-0 gy-4'>
                                            <thead>
                                                <tr className='fw-bold text-muted bg-light'>
                                                    <th className='ps-4 rounded-start'>Image</th>
                                                    <th className=' rounded-start'>Nom Produit</th>
                                                    <th className='text-center'>Quantité</th>
                                                    <th className='text-center'>Prix</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orders.map((item) =>
                                                    item.orderItems.map((o) => (
                                                        <tr key={o.id}>
                                                            <td>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='symbol symbol-50px'>
                                                                        <img
                                                                            src={`${uploadUrl}/${o.product
                                                                                ?
                                                                                `product_images/${o.product?.image}`
                                                                                :
                                                                                `pack_images/${o.pack?.image}`}`}
                                                                            className='order-details-img'
                                                                            alt={item.product?.title}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td className='td-min-w'>
                                                                <span className='ff-varela-round text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                    {`${o.product ? o.product?.title : o.pack?.title}`}
                                                                </span>
                                                            </td>

                                                            <td className='text-center'>
                                                                <span className='ff-varela-round clr-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                    {o.quantity}
                                                                </span>
                                                            </td>

                                                            <td className='text-center'>
                                                                <span className='ff-varela-round clr-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                    {o.product ? o.product?.sellingPrice : o.pack?.sellingPrice} DH
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>

                                    </div>
                                    :
                                    ''
                            }

                        </div>
                }
            </div>
        </>
    );
};


const UserOrderDetails: React.FC = () => {

    return (
        <>
            <UserDashboard secondComponent={Content} />
        </>
    );
};

export default UserOrderDetails;