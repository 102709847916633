import { FC, useState, useEffect } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { ProductProps } from '@helpers/Props'
import { ReactHelmet, apiUrl, valueText } from '@helpers/Helpers'
import { hooks, formHelper } from '@helpers/Index'
import { applyFiltersAndSort, handleSortChange } from '@components/ProductFilter/applyFiltersAndSort'
import Layout from '../../../../layouts/Layout'
import ProductFilter from '@components/ProductFilter/ProductFilter'
import ProductFetch from '@components/ProductFilter/ProductFetch'
import '../../Pages/BoutiqueStyle.css'
import BrandSwiper from '@components/Carousel/BrandSwiper/BrandSwiper'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import { useCategoriesContext } from '@contexts/CategoriesContext'
import { useMarquesContext } from '@contexts/MarquesContext'
import useApiRequest from '@mbs-dev/api-request';

const Boutique: FC = () => {

  const { windowWidth } = useWindowWidthContext()
  const { categories } = useCategoriesContext()
  const { marques } = useMarquesContext()
  const { marque, categorie, subCategorie } = useParams()
  const { apiRequest } = useApiRequest()

  const [sortBy, setSortBy] = useState('')
  const [isGrid, setIsGrid] = useState(true)
  const [isFilter, setIsFilter] = useState(true)
  const [sortByDate, setSortByDate] = useState("")
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [productMarque, setProductMarque] = useState<number>()
  const [productCategory, setProductCategory] = useState<number>()
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false)


  useEffect(() => {
    const getMarque = marques.find(item => marque === item.slug);
    setProductMarque(getMarque?.id);
  }, [marque]);

  useEffect(() => {
    const getCategory = categories.find(item => categorie === item.slug);
    const getSubCategory = getCategory?.subCategory.find(item => subCategorie === item.slug);

    if (getSubCategory) {
      setProductCategory(getSubCategory?.id);
    } else {
      setProductCategory(getCategory?.id);
    }

  }, [categorie, subCategorie]);


  const route =
    productMarque ?
      `total-products-bymarque/${marque}`
      : productCategory ?
        `total-products-bycategory/${subCategorie ? subCategorie : categorie}`
        : 'total-products'

  const { minPrice, maxPrice, totalProducts } = hooks.useProductInfo(route)
  const [filteredPrice, setFilteredPrice] = useState<number[]>([minPrice, 2000])

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<ProductProps[], Error>(
    ['products', { productMarque, productCategory, marque, categorie, subCategorie }],
    async ({ pageParam = 1 }) => {
      setIsLoadingData(true)
      setIsLoadingMoreData(true)

      const response = await apiRequest({
        route: `${apiUrl}/products.json`,
        method: 'GET',
        params: {
          disponibilite: 'enstock',
          marque: categorie ? null : productMarque || null,
          categorie: marque ? null : productCategory || null,
          page: pageParam,
          itemsPerPage: 16,
        }
      });

      setIsLoadingData(false)
      setIsLoadingMoreData(false)

      return response.data
    },
    {
      getNextPageParam: (lastPage, pages) => pages.length + 1,
    }
  )

  const products = data?.pages.flat() || []
  let filteredData = applyFiltersAndSort([...products], filteredPrice, sortBy, sortByDate)


  useEffect(() => {
    if (maxPrice) {
      setFilteredPrice([minPrice, maxPrice])
    }
  }, [maxPrice])

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setFilteredPrice(newValue as number[])
  }

  const handleSortChangeWrapper = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleSortChange(event.target.value, setSortBy, setSortByDate, setIsFilter)
  }


  return (
    <Layout>
      <ReactHelmet title='Rose Et Bleu - Produits' />
      {/* <BrandSwiper brands={marques} page='' /> */}
      {/* <BrandMultiCarousel brands={marques} page='' /> */}

      <div className={`boutique mt-2rem ${isGrid ? 'isgrid' : 'isrow'}`}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            {products ?
              <ProductFilter
                url=''
                sortBy={sortBy}
                marques={marques}
                isFilter={isFilter}
                maxPrice={maxPrice}
                categories={categories}
                windowWidth={windowWidth}
                filteredPrice={filteredPrice}
                valueText={valueText}
                setIsGrid={(setIsGrid)}
                setIsFilter={setIsFilter}
                setProductMarque={setProductMarque}
                handlePriceChange={handlePriceChange}
                setProductCategory={setProductCategory}
                handleSortChange={handleSortChangeWrapper}
              />
              :
              null
            }

            <ProductFetch
              isGrid={isGrid}
              windowWidth={windowWidth}
              filteredData={filteredData}
              isLoadingData={isLoadingData}
              totalProducts={totalProducts}
              fetchNextPage={fetchNextPage}
              isLoadingMoreData={isLoadingMoreData}
              isFetchingNextPage={isFetchingNextPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Boutique