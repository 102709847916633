import { EmblaOptionsType } from 'embla-carousel'
import { PrevButton, NextButton, usePrevNextButtons } from './EmblaCarouselArrowButtons'
import { Link } from 'react-router-dom'
import { MarqueProps } from '@helpers/Props'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import { marqueImgUrl } from '@helpers/Helpers'
import useEmblaCarousel from 'embla-carousel-react'
import './BrandEmblaCarousel.css'
import { HeaderContainer } from '@helpers/Index'

type PropType = {
  marques: MarqueProps[]
  options?: EmblaOptionsType
}

const BrandEmblaCarousel: React.FC<PropType> = (props) => {
  const { marques, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const { windowWidth } = useWindowWidthContext()

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  return (
    <>
      {marques.length > 0 ?
        <div className="container embla-brand mb-4rem">
          <HeaderContainer className={`fw-bold mb-2rem fs-37px ff-outfit-semi`} title="NOS" titleTwo='MARQUES' />
          <section className="embla">
            <div className="embla__viewport" ref={emblaRef}>
              <div className="embla__container">
                {marques.map((item) => (
                  <div className="embla__slide d-flex-center" key={item.id}>
                    <Link key={item.id} to={`/boutique/marque/${item.slug}`} className="nav-link">
                      <img
                        className="embla__slide-img radius-10px"
                        src={`${marqueImgUrl}/${item?.image}`}
                        alt={item.title}
                      />
                    </Link>
                    <div className="text-center d-none">
                      <p className={`${windowWidth < 644 ? 'mt-1 mb-1rem' : 'mb-3rem'} fs-15px ff-0 fw-600`}>{item.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="embla__controls d-none">
              <div className="embla__buttons">
                <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
              </div>

            </div>
          </section>
        </div>
        : null
      }
    </>
  )
}

export default BrandEmblaCarousel
