import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApiRequest from '@mbs-dev/api-request';
import { TruncateText, apiUrl, productImgUrl, variantIconUrl, variantImgUrl } from '@helpers/Helpers';
import { AuthUserProps, PricingProps, ProductCardProps, VariantProps } from '@helpers/Props';
import { FrCustomeErrorNorify } from '@helpers/Toastify';
import { loadingHelper } from '@helpers/Index';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import { useShoppingCart } from '@contexts/ShoppingCartContext';
import Icons from '@helpers/IconsHelper';
import './ProductCardStyle.css'

const ProductCard: FC<ProductCardProps> = ({ productIdsObj, item, isGrid, bsCol = 'col-md-6 col-lg-6 col-xl-4 col-xxl-3 col-sm-6' }) => {

  // Global
  const { apiRequest } = useApiRequest();
  const { addToCart } = useShoppingCart();
  const [discount, setDiscount] = useState(null);
  const [haveDiscount, setHaveDiscount] = useState(false);
  const [isGetDiscount, setIsGetDiscount] = useState(false);
  const [productPrice, setProductPrice] = useState<number>(item.sellingPrice);
  const [variantPrice, setVariantPrice] = useState<number>(item.sellingPrice);
  const [isVariant, setIsVariant] = useState(false);
  const [isDisplayAll, setIsDisplayAll] = useState(false);
  const [isWishlistHover, setIsWishlistHover] = useState(false);
  const { windowWidth } = useWindowWidthContext()

  // Selected Variant
  const [selectedVariant, setSelectedVariant] = useState<any>('');
  const [selectedVariantId, setSelectedVariantId] = useState<number>();
  const [selectedVariantImage, setSelectedVariantImage] = useState<string>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  // User
  const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);
  const storedUser = localStorage.getItem('mbs_user');

  useEffect(() => {
    if (storedUser) {
      setAuthUser(JSON.parse(storedUser));
    }
  }, []);

  const fetchData = async () => {
    try {
      setIsGetDiscount(false)
      if (productIdsObj.isProductIds) {
        if (productIdsObj.productIds.length > 0) {
          if (productIdsObj.productIds.includes(item.id)) {

            const response = await apiRequest({
              route: `${apiUrl}/promotion-discount/product_id/${item.id}`,
              method: 'GET',
            });

            if (response.status === 200) {
              setDiscount(response.data.discount)
              setIsGetDiscount(true)
              if (response.data.discount !== null) {
                setHaveDiscount(true)
                setProductPrice(item.sellingPrice - (item.sellingPrice * (response.data.discount / 100)))
              }
              else {
                setProductPrice(item.sellingPrice)
              }
            } else {
              setIsGetDiscount(false)
              setHaveDiscount(false)
              FrCustomeErrorNorify()
            }
          }// End check includes if
          else {
            setIsGetDiscount(true)
            setHaveDiscount(false)
            setProductPrice(item.sellingPrice)
          }
        } else {
          setIsGetDiscount(true)
        }
      }

    } catch {

    }
  }

  useEffect(() => {
    fetchData();
    if (item.variant && item.variant?.length > 0) {
      const selectedOne = isGrid ? `${item.variant[0].label && item.variant[0].label}` : `${item.variant[0].reference} ${item.variant[0].label && item.variant[0].label}`
      setSelectedVariant(selectedOne)
      setSelectedVariantId(item.variant[0].id)
      setSelectedVariantImage(item.variant[0].image)
    }
  }, [item.id, productIdsObj.productIds, haveDiscount])


  useEffect(() => {
    setIsVariant(false)
    if (haveDiscount && discount !== null && item.variant && item.variant?.length > 0) {
      let sellingPrice = item.variant[selectedIndex].sellingPrice
      if (sellingPrice !== item.sellingPrice) {
        setVariantPrice(sellingPrice - (sellingPrice * (discount / 100)))
        setIsVariant(true)
      }
    }
  }, [item.id, selectedIndex, selectedVariantId])

  const Pricing = ({ price, selling }: PricingProps) => {
    return (
      <>
        {
          isGrid && windowWidth < 590 ?
            <>
              <p className=''><del className='me-2 discount-price ff-varela-round fw-600'>{selling?.toFixed(2)} DH</del></p>
              <p className='me-1rem mb-0'><span className='clr-pink'>{price?.toFixed(2)} DH</span></p>
            </>
            :
            <>
              <p className='me-1rem mb-0'><span className='clr-pink'>{price?.toFixed(2)} DH</span></p>
              <p className='mb-0'><del className='me-2 discount-price ff-varela-round fw-600'>{selling?.toFixed(2)} DH</del></p>
            </>
        }

      </>
    )
  }

  const hideIcon = () => {
    setIsWishlistHover(false);
  };


  return (
    <div
      className=
      {` ${bsCol} p-0 product-item
      ${isGrid ? 'col-6 isgrid' : 'col-12 isrow'}
      ${isGrid ? item.variant.length > 0 ? 'max-h-28rem' : 'max-h-28rem' : ''} `}
      onMouseLeave={hideIcon} key={item.slug}>

      <div className="container">
        <div className="row d-flex-center">

          {/* Start Product Image */}
          <div className={`product-image text-center dflex justify-content-center ${isGrid && windowWidth < 590 ? 'h-15rem' : 'h-22rem'}`}>
            {haveDiscount
              ?
              <span className='position-fixed discount-value '>{`-${discount} %`}</span>
              :
              null
            }
            <Link to={`/boutique/product/${item.slug}`} state={item} onMouseLeave={hideIcon} onMouseEnter={hideIcon} >
              <img
                className="product-img w-100 h-80 object-fit-cover mb-5"
                alt={item.title.replace('🇹🇷', '')}
                src={`${selectedVariantImage ?
                  variantImgUrl : productImgUrl}/${selectedVariantImage ?
                    selectedVariantImage :
                    item.image}`} />
            </Link>
          </div>
          {/* End Product Image */}

          {/* Start Product Info*/}
          <div className="text-start">
            <div className="product-details text-start">
              <div className={`product-info text-start ${item.variant && item.variant.length > 1 ? '' : 'mt-2px'}`}>
                <Link className='nav-link' to={`/boutique/product/${item.slug}`} state={item}>
                  <div className={`product-title text-cap ff-montserrat-reg ${item.variant && item.variant.length > 1 ? 'vr-line-height' : 'mt-0rem pr-line-height'}`}>
                    <TruncateText text={item.title.replace('🇹🇷', '')} maxLength={item.variant && item.variant.length > 0 ? 48 : 55} />
                  </div>
                </Link>

                {item.variant && item.variant?.length > 1 ?
                  <div className={`row variant-row text-start ${isGrid && windowWidth < 590 ? 'isgrid' : 'isrow'} ${selectedVariant ? 'variant-row-m2' : 'variant-row-m1'}`}>
                    <div className={`variant-ref-content pb-0 ${windowWidth < 640 && isGrid ? 'max-w-195px' : ''} ${isGrid ? 'ps-6px ox-hidden white-space-nowrap' : 'ps-6px isrow'}`}>
                      {selectedVariant ?
                        <span className='ff-montserrat-reg fs-14px clr-var'><TruncateText text={selectedVariant} maxLength={isGrid ? 30 : 48} /></span>
                        :
                        null}
                    </div>
                    {item.variant?.slice(0, isGrid ? windowWidth > 768 ? 5 : 3 : 5).map((v: VariantProps, index: number) => {
                      const selected = isGrid ? `${v.label && v.label}` : `${v.reference && v.reference} ${v.label && v.label}`;

                      return (
                        <div
                          key={v.id}
                          className={`variant-icon-content mx-1 d-flex-center c-pointer ${selectedVariant === selected ? 'selected-border' : 'notselected-border'}`}
                          onClick={() => {
                            const selected = isGrid ? `${v.label && v.label}` : `${v.reference && v.reference} ${v.label && v.label}`;

                            setSelectedVariant(selected);
                            setSelectedVariantId(v.id);
                            setSelectedVariantImage(v.image);
                            setSelectedIndex(index);
                            setIsDisplayAll(false)
                          }}
                        >
                          <div className="variant-icon d-flex-center">
                            <img src={`${variantIconUrl}/${v.icon}`} alt={v.label} className='variant-icon-img' />
                          </div>
                        </div>
                      );
                    })}
                    {windowWidth > 768 && item.variant.length > 5 ?
                      <Icons.BsPlusCircle className={`fs-36px c-pointer more-variant-icon more-variant-icon-${isGrid ? 'grid' : 'row'}-left `} onClick={() => setIsDisplayAll(!isDisplayAll)} />
                      :
                      null
                    }
                    {windowWidth < 768 && isGrid && item.variant.length > 5 ?
                      <Icons.BsPlusCircle className={`fs-36px c-pointer more-variant-icon more-variant-icon-${isGrid ? 'grid' : 'row'}-left `} onClick={() => setIsDisplayAll(!isDisplayAll)} />
                      :
                      null
                    }
                    {windowWidth < 768 && !isGrid && item.variant.length > 5 ?
                      <Icons.BsPlusCircle className={`fs-36px c-pointer more-variant-icon more-variant-icon-${isGrid ? 'grid' : 'row'}-left `} onClick={() => setIsDisplayAll(!isDisplayAll)} />
                      :
                      null
                    }
                  </div>
                  :
                  null
                }


                {/* Start display all variant */}
                <div className={`display-all-variant p-2 row ${isDisplayAll ? 'd-flex flex-wrap' : 'd-none'}`}>
                  {item.variant?.map((v: VariantProps, index: number) => {
                    const selected = isGrid ? `${v.label && v.label}` : `${v.reference && v.reference} ${v.label && v.label}`
                    return (
                      <div
                        key={v.id}
                        className={`variant-icon-content mx-1 d-flex-center c-pointer mb-2 ${selectedVariant === selected ? 'selected-border' : 'notselected-border'}`}
                        onClick={() => {
                          const selected = isGrid ? `${v.label && v.label}` : `${v.reference && v.reference} ${v.label && v.label}`;

                          setSelectedVariant(selected)
                          setSelectedVariantId(v.id)
                          setSelectedVariantImage(v.image)
                          setSelectedIndex(index)
                          setIsDisplayAll(false)
                        }}>

                        <div className="variant-icon ">
                          <img src={`${variantIconUrl}/${v.icon}`} alt={v.label} className='variant-icon-img' />
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
                {/* End display all variant */}


                {/* Start Add to cart */}
                <div className={`row add-to-cart-container fixed-bottom d-flex-between mmb-1 bottom-26px ${isGrid && windowWidth < 590 ? 'isgrid' : 'isrow'}`}>
                  {/* <hr className='hr-tag m-hr' /> */}
                  <div className={`col-${isGrid && windowWidth < 590 ? 12 : 12} text-start ps-0 pe-0 mb-10px`}>
                    <div className={`product-price fixedbottom ff-varela-round fw-600 d-flex ${isGrid && windowWidth < 590 ? 'd-flex-center' : ''}`}>
                      {haveDiscount
                        ? isVariant ?
                          <Pricing price={variantPrice} selling={item.variant[selectedIndex].sellingPrice} />
                          :
                          <Pricing price={productPrice} selling={item.sellingPrice} />
                        :
                        <span>{productPrice} DH</span>
                      }
                    </div>
                  </div>

                  <div className={`col-${isGrid && windowWidth < 590 ? 12 : 12} ${isGrid && windowWidth < 590 ? 'px-0' : 'p-0'} text-end shopping-cart-add-to d-flex justify-content-end`}>
                    {isGetDiscount ?
                      <div
                        className={`${false ? 'shopping-cart-icon-parent' : 'addtocart-parent p-0'} d-flex-center  c-pointer ff-montserrat-reg`}
                        onClick={() => {
                          const variantId = selectedVariantId ? selectedVariantId : '';
                          if (item.variant && item.variant?.length > 0) {
                            addToCart(item.id, isVariant ? Number(variantPrice) : productPrice, 'product', variantId, 1, item.sellingPrice, true);
                          } else {
                            addToCart(item.id, isVariant ? Number(variantPrice) : productPrice, 'product', '', 1, item.sellingPrice);
                          }
                        }}
                      >
                        {false ?
                          <Icons.MdShoppingCart className='shopping-cart-icon' />
                          :
                          'Ajouter au panier'
                        }
                      </div>
                      :
                      <div className="loading-discount">
                        <loadingHelper.DottedLoading />
                      </div>
                    }
                  </div>
                </div>
                {/* End Add to cart */}


              </div>
            </div>
          </div>
          {/* End Product Image */}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;