import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

interface GuardProps {
    children: ReactNode
}


export const CheckoutGuard: React.FC<GuardProps> = ({ children }) => {
    const isguarded = localStorage.getItem('isguarded')

    if (!isguarded) {
        return <Navigate to="/my-cart" />
    }

    return <>{children}</>
}


export const OfflineCheckoutGuard: React.FC<GuardProps> = ({ children }) => {
    const isguarded = localStorage.getItem('isofflineguarded')

    if (!isguarded) {
        return <Navigate to="/my-cart" />
    }

    return <>{children}</>
}
