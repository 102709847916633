import { useEffect, useState, Fragment } from 'react';
import { ShoppingCartItem } from '@components/Cart/ShoppingCartItem/ShoppingCartItem';
import { Link, useNavigate } from 'react-router-dom';
import { ReactHelmet, apiUrl } from '@helpers/Helpers';
import { InfoNotify, notify, ErrorNotify, FrCustomeErrorNorify } from '@helpers/Toastify';
import { AuthUserProps, CodePromoProps } from '@helpers/Props';
import { checkoutImage, emptyCart, SubmitButton, formHelper, creditCards, cashOndelivery } from '@helpers/Index';
import { codePpromoSchema } from '@helpers/YupHelper';
import { useShoppingCart } from '@contexts/ShoppingCartContext';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import Layout from '../../layouts/Layout';
import useApiRequest from '@mbs-dev/api-request';
import Icons from '@helpers/IconsHelper';
import './ShoppingCart.css';

const ShoppingCart: React.FC = () => {

    const mbs_user_token = localStorage.getItem('mbs_user_token');
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState('livraison');
    const { cartItems, total, cartQuantity, setOrderData, updateCartItemsWithPromotions } = useShoppingCart()
    const [name, setName] = useState('');
    const [discount, setDiscount] = useState(0);
    let [newTotal, setNewTotal] = useState(total);
    const [codePromoState, setCodePromoState] = useState(false);
    const [codePromo, setCodePromo] = useState<CodePromoProps>();
    const [userPromoCode, setUserPromoCode] = useState<number>();
    const { apiRequest } = useApiRequest();
    const { windowWidth } = useWindowWidthContext()
    const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);
    const storedUser = localStorage.getItem('mbs_user');
    localStorage.setItem('isguarded', 'true')
    localStorage.setItem('isofflineguarded', 'true')

    useEffect(() => {
        if (storedUser) {
            setAuthUser(JSON.parse(storedUser));
        }
    }, []);


    const promoFormik = formHelper.useFormik({
        initialValues: {
            codepromo: "",
        },
        validationSchema: codePpromoSchema,
        onSubmit: async (values, { resetForm }) => {
            try {

                const response = await apiRequest({
                    route: `${apiUrl}/code_promos.json?name=${values.codepromo}`,
                    method: 'GET',
                    requiresAuth: true,
                    token: `${localStorage.getItem('mbs_user_token')}`
                });

                if (response.status === 200) {


                    if (codePromoState === false) {
                        if (response.data[0] !== undefined) {

                            const today = new Date();
                            today.setHours(0, 0, 0, 0);
                            const formattedDate = today.toISOString().split('T')[0] + 'T00:00:00+00:00';

                            if (new Date(response.data[0].dateFin) >= new Date(formattedDate)) {
                                notify('Code promo appliqué avec succès');
                                setName(response.data[0].name);
                                setDiscount(response.data[0].discount)
                                setCodePromoState(true);

                                const data = {
                                    user: `api/users/${authUser?.id}`,
                                    codePromo: `api/code_promos/${response.data[0].id}`
                                }

                                const codeResponse = await apiRequest({
                                    route: `${apiUrl}/user_promo_codes`,
                                    method: 'POST',
                                    data: data,
                                    requiresAuth: true,
                                    token: `${localStorage.getItem('mbs_user_token')}`
                                });

                                if (codeResponse.status === 201) {
                                    setUserPromoCode(codeResponse.data.id)
                                }
                            }
                            else if (new Date(response.data[0].dateFin) < new Date(formattedDate)) {
                                ErrorNotify('Ce code promo est expiré');
                            }
                        } else {
                            ErrorNotify("Code promo n'existe pas");
                        }
                    }

                    else if (codePromoState === true) {
                        InfoNotify('Code promo dejà appliqué');
                    }
                }

            } catch (error) {
                FrCustomeErrorNorify()
            }
        },
    });

    const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentMethod(event.target.value);
    };

    const deleteCodePromo = async () => {
        setCodePromoState(false)
        notify('Code promo bien supprimé')

        if (userPromoCode !== undefined) {
            const response = await apiRequest({
                route: `${apiUrl}/user_promo_codes/${userPromoCode}`,
                method: 'DELETE',
                requiresAuth: true,
                token: `${localStorage.getItem('mbs_user_token')}`
            });
        }
    }

    const valider = async () => {
        if (!mbs_user_token) {
            navigate('/login');
            InfoNotify('Vous devrez être connecté pour effectuer cette action!');
        }
        else {
            const response = await apiRequest({
                route: `${apiUrl}/user/check-user-info/${authUser?.id}`,
                method: 'GET',
                requiresAuth: true,
                token: `${localStorage.getItem('mbs_user_token')}`
            });

            if (response.status === 200) {
                if (response.data.result === 'true') {
                    navigate('/checkout');
                }
                else {
                    navigate('/my-account/edit-account')
                    InfoNotify('Merci de compléter les details de votre compte')
                }
            }
        }
    }

    const goToAntiCheckOut = () => {
        navigate('/offline-checkout');
    }

    useEffect(() => {

        if (paymentMethod === 'carte') {
            if (codePromoState === true) {
                setNewTotal(total - (total * ((discount + 5) / 100)))
            }
            if (codePromoState === false) {
                setNewTotal(total - (total * 0.05))
            }
        }

        if (paymentMethod === 'livraison') {
            if (codePromoState === true) {
                setNewTotal(total - ((total * discount) / 100))
            }
            if (codePromoState === false) {
                setNewTotal(total)
            }
        }

        if (newTotal >= 300) {
            const order = {
                paymentMethod: paymentMethod,
                totalPrice: newTotal
            }

            setOrderData(order);
        }

        if (newTotal < 300) {
            if (paymentMethod === 'livraison') {
                const order = {
                    paymentMethod: paymentMethod,
                    totalPrice: newTotal + 40
                }

                setOrderData(order);
            }

            if (paymentMethod === 'carte') {
                const order = {
                    paymentMethod: paymentMethod,
                    totalPrice: newTotal + 40
                }

                setOrderData(order);
            }

        }

    }, [discount, total, codePromoState, newTotal, paymentMethod])

    useEffect(() => {
        updateCartItemsWithPromotions()
    }, [])


    return (
        <Layout>
            <ReactHelmet title='Mon panier' />

            <div className="mt-23px shopping-card d-flex justify-content-betdwen align-items-center">
                <div className="container product-cdontainer">
                    {cartQuantity < 1 ?
                        <>
                            <div className="row pt-5">
                                <div className="col-12 d-flex-center col-">
                                    <img src={emptyCart} alt="empty cart" className='shopping-cart-empty-image' />
                                </div>
                                <div className="col-12 mt-3 d-flex-center col-">
                                    <h3>Votre panier est actuellement vide.</h3>
                                </div>
                                <div className="col-12 mt-3 d-flex-center col-">
                                    <Link to='/boutique' className='nav-link back-to-boutique'>RETOUR À LA BOUTIQUE</Link>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className={`row ${windowWidth < 578 ? 'px-3' : ''}`}>
                                <div className="col-md-12 col-lg-12 col-xl-7 pb-0 shopping-cart-items">
                                    <div className="cart-items">
                                        {cartItems.map((item) => (
                                            <Fragment key={item.ukey}>
                                                <ShoppingCartItem key={item.ukey} itemKey={item.ukey} variantId={item.variant} {...item} isvariant={item.isvariant} price={item.price} />
                                            </Fragment>
                                        ))}
                                    </div>
                                    <form onSubmit={promoFormik.handleSubmit}>

                                        <div className="promo">
                                            <div className="code-promo d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="code-promo-value d-flex ms-1 align-items-center">
                                                    <div className="code-promo-image">
                                                        <img src={checkoutImage} alt="code promo" />
                                                    </div>
                                                    <div className="code-promo-input ms-2">
                                                        <div className="form-group">
                                                            <formHelper.FormInput
                                                                spanValue="Code promo"
                                                                name="codepromo"
                                                                value={promoFormik.values.codepromo}
                                                                type='text'
                                                                className={promoFormik.touched.codepromo && promoFormik.errors.codepromo ? "is-invalid" : ""}
                                                                touched={promoFormik.touched}
                                                                errors={promoFormik.errors}
                                                                handleChange={promoFormik.handleChange}
                                                                handleBlur={promoFormik.handleBlur}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="code-promo-btn">
                                                    <SubmitButton
                                                        className="mt-1 px-2rem py-14px"
                                                        btnLabel="APPLIQUER LE CODE PROMO"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className={`col-md-12 col-lg-12 col-xl-5 ${windowWidth < 1200 ? 'p-0' : ''}`}>
                                    <div className="shopping-cart-checkout min-h-38rem">
                                        <div className="row">
                                            <div className="col-12 d-none">
                                                <p className="shopping-cart-note">
                                                    <Icons.BsStars className='shopping-cart-note-icon' />
                                                    Remise de 5% en cas de paiement par carte bancaire
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <p className="shopping-cart-note">
                                                    <Icons.BsStars className='shopping-cart-note-icon' />
                                                    Livraison gratuite a partir de : 300 DH
                                                </p>
                                            </div>
                                        </div>
                                        <hr className='hr-tag' />

                                        <div className="row">

                                            <div className="shopping-cart-checkout-title d-none">
                                                <span>TOTAL PANIER</span>
                                                <hr className='hr-tag' />
                                            </div>

                                            <div className="shopping-cart-checkout-soustotal d-flex justify-content-between px-4 mt-2 mb-3">
                                                <div className="cart-subtotal-title">SOUS-TOTAL</div>
                                                <div className="shopping-cart-checkout-soustotal-value">{total.toFixed(2)} DH</div>
                                            </div>
                                            <hr className='hr-tag' />

                                            <div className=" shopping-cart-checkout-expedition d-flexx justify-content-betweenn px-4 mt-2 mb-3">
                                                <div className="row">
                                                    <div className="col-12 col-lg-4 col-md-12">
                                                        <div className="shopping-cart-checkout-expedition-title mb-2">
                                                            <span>EXPÉDITION</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-8 col-md-12">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="forfait mb-3">
                                                                    {
                                                                        paymentMethod === 'carte' && Number(total.toFixed(2)) < 300 &&
                                                                        (<Icons.BsCheckCircleFill className='clr-success shopping-cart-free-icon' />)
                                                                    }
                                                                    {
                                                                        paymentMethod !== 'carte' && Number(total.toFixed(2)) < 300 &&
                                                                        (<Icons.BsCheckCircleFill className='clr-success shopping-cart-free-icon' />)
                                                                    }
                                                                    {
                                                                        paymentMethod === 'carte' && Number(total.toFixed(2)) >= 300 &&
                                                                        (<Icons.BsCircle className='clr-danger shopping-cart-free-icon' />)
                                                                    }
                                                                    {
                                                                        paymentMethod !== 'carte' && Number(total.toFixed(2)) >= 300 &&
                                                                        (<Icons.BsCircle className='clr-danger shopping-cart-free-icon' />)
                                                                    }

                                                                    <span className='shopping-cart-livraison  '>Forfait de livraison : 40 DH</span>
                                                                </div>

                                                            </div>
                                                            <div className="col-12">

                                                                <div className="forfait">
                                                                    {
                                                                        paymentMethod === 'carte' && Number(total.toFixed(2)) < 300 &&
                                                                        (<Icons.BsCircle className='clr-danger shopping-cart-free-icon' />)
                                                                    }
                                                                    {
                                                                        paymentMethod !== 'carte' && Number(total.toFixed(2)) < 300 &&
                                                                        (<Icons.BsCircle className='clr-danger shopping-cart-free-icon' />)
                                                                    }
                                                                    {
                                                                        paymentMethod === 'carte' && Number(total.toFixed(2)) >= 300 &&
                                                                        (<Icons.BsCheckCircleFill className='clr-success shopping-cart-free-icon' />)
                                                                    }
                                                                    {
                                                                        paymentMethod !== 'carte' && Number(total.toFixed(2)) >= 300 &&
                                                                        (<Icons.BsCheckCircleFill className='clr-success shopping-cart-free-icon' />)
                                                                    }

                                                                    <span className='shopping-cart-livraison  '>Livraison Gratuite</span>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                                {codePromoState && (
                                                    <>
                                                        <hr className='hr-tag' />

                                                        <div className="row justify-content-between">
                                                            <div className="col-12">
                                                                <div className="cart-subtotal-title">Code Promo : <span className='text-danger'>{name}</span></div>
                                                                <div className="cart-subtotal-title">Remise : <span className='text-danger'>{discount}%</span></div>

                                                            </div>
                                                            <div className="col-12 text-end">
                                                                <button onClick={() => deleteCodePromo()} className="btn-delete-codepromo mt-5" >
                                                                    Supprimer
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                            <hr className='hr-tag' />

                                            <div className="shopping-cart-checkout-payment mb-3">
                                                <div className="row d-flex-center text-center">
                                                    <span className='text-start px-4 fs-14px mb-4 text-upper'>Methode de payment</span>
                                                    <div className="col-6 col-md-4 col-lg-4  align-items-center text-center d-fDlex">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input shopping-form-check-input"
                                                                type="radio"
                                                                name="payment"
                                                                id="livraison"
                                                                value="livraison"
                                                                checked={paymentMethod === 'livraison'}
                                                                onChange={handlePaymentMethodChange}
                                                            />
                                                            <label className="form-check-label" htmlFor="livraison">
                                                                À la livraison
                                                            </label>
                                                        </div>
                                                        <label htmlFor="livraison">
                                                            <img src={cashOndelivery} alt="cash" className='shopping-cart-checkout-payment-mastercard' />
                                                        </label>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-4  align-items-center text-center d-none">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input shopping-form-check-input"
                                                                type="radio"
                                                                name="payment"
                                                                id="carte"
                                                                value="carte"
                                                                checked={paymentMethod === 'carte'}
                                                                onChange={handlePaymentMethodChange}
                                                            />
                                                            <label className="form-check-label" htmlFor="carte">
                                                                Carte bancaire
                                                            </label>
                                                        </div>
                                                        <label htmlFor="carte">
                                                            <img src={creditCards} alt="mastercard" className='shopping-cart-checkout-payment-mastercard' />
                                                        </label>

                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='hr-tag' />

                                            <div className="row">
                                                <div className="shopping-cart-checkout-total d-flex justify-content-between px-4 mb-5">
                                                    <div className="shopping-cart-checkout-total-title px-">TOTAL</div>
                                                    <div className="shopping-cart-checkout-total-value">
                                                        {
                                                            paymentMethod === 'carte'
                                                                ? Number(newTotal.toFixed(2)) < 300
                                                                    ?
                                                                    (newTotal + 40).toFixed(2)
                                                                    :
                                                                    newTotal.toFixed(2)
                                                                : Number(newTotal.toFixed(2)) < 300
                                                                    ?
                                                                    (newTotal + 40).toFixed(2)
                                                                    :
                                                                    newTotal.toFixed(2)
                                                        }
                                                        DH</div>

                                                </div>

                                                <div className="row d-flex justify-content-start pt-1rem">
                                                    <div className={`col-lg-7 col-md-7 col-12`}>
                                                        <div className={`text-start ${windowWidth < 578 ? 'col12' : 'col10'}`}>
                                                            <SubmitButton
                                                                className="mt-1 px-2rem py-14px"
                                                                btnLabel={`${authUser ? 'Valider la commande' : 'Se Connecter'}`}
                                                                onClick={() => (valider())}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={`col-lg-5 col-md-5 col-12 ${authUser ? 'd-none' : ''}`}>
                                                        <div className={`text-start ${windowWidth < 578 ? 'col12' : 'col10'}`}>
                                                            <SubmitButton
                                                                className="mt-1 px-3rem py-14px"
                                                                btnLabel="Acheter"
                                                                onClick={() => (goToAntiCheckOut())}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default ShoppingCart;