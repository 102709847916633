import './SpecialOffer.css'
import { SpecialOffersProps } from '@helpers/Props';
import { apiUrl } from '@helpers/Helpers';
import { EmblaOptionsType } from 'embla-carousel'
import { useInfiniteQuery } from 'react-query';
import SpecialOfferCarousel from '@components/Carousel/SpecialOfferCarousel/SpecialOfferCarousel';
import useApiRequest from '@mbs-dev/api-request';
import TodayOfferCarousel from '@components/Carousel/SpecialOfferCarousel/TodayOfferCarousel';

const OPTIONS: EmblaOptionsType = {}

const SpecialOffer: React.FC = () => {
    const { apiRequest } = useApiRequest()

    const {
        data,
        isLoading: isFetching,
    } = useInfiniteQuery<SpecialOffersProps[], Error>(
        'special_offers',
        async () => {
            const response = await apiRequest({
                route: `${apiUrl}/special_offers.json`,
                method: 'GET',
                params: {
                    page: 1,
                    itemsPerPage: 4,
                }
            })

            return response.data;

        }
    );

    const SpecialOffers = data?.pages.flat() || [];

    return (
        <>
            <SpecialOfferCarousel slides={SpecialOffers} options={OPTIONS} isLoading={isFetching} />
            <TodayOfferCarousel slides={SpecialOffers} options={OPTIONS} isLoading={isFetching} />
        </>
    )
}

export default SpecialOffer
