import { createContext, useContext, useEffect, useState } from 'react';
import { CategorieProps } from '@helpers/Props';
import { useInfiniteQuery, useQuery } from 'react-query';
import { apiUrl } from '@helpers/Helpers';
import useApiRequest from '@mbs-dev/api-request';


const CategoriesContext = createContext<{
    categories: CategorieProps[];
}>({
    categories: []
});

export const useCategoriesContext = () => useContext(CategoriesContext);

export const CategoriesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // const [categories, setCategories] = useState<CategorieProps[]>([]);
    const { apiRequest } = useApiRequest()

    // const fetchData = async () => {
    //     try {
    //         const response = await apiRequest({
    //             route: 'parents-categories',
    //             method: 'GET',
    //         });

    //         setCategories(response.data)

    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    // useEffect(() => {
    //     fetchData();
    // }, [])

    const {
        data,
        isLoading: isFetching,
    } = useInfiniteQuery<CategorieProps[], Error>(
        // } = useInfiniteQuery<SliderProps[], Error>(
        'categories',
        async () => {
            const response = await apiRequest({
                route: `${apiUrl}/parents-categories`,
                method: 'GET',
            });
            return response.data;

        }
    );

    const categories = data?.pages.flat() || []

    return (
        <>
            <CategoriesContext.Provider value={{ categories }}>
                {children}
            </CategoriesContext.Provider>
        </>

    );
};
