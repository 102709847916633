import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BsChatRightDots } from 'react-icons/bs'
import { OfflineOrderProps } from '@helpers/Props';
import useApiRequest from '@mbs-dev/api-request';
import { apiUrl, formatDate, uploadUrl } from '@helpers/Helpers';
import { AiOutlineEye } from 'react-icons/ai';
import { Helmet } from 'react-helmet-async';
import { FrCustomeErrorNorify, notify } from '@helpers/Toastify';
import AnimatedLoading from '@components/Loading/AnimatedLoading';
import DottedLoading from '@components/Loading/DottedLoading';
import Layout from '../../layouts/Layout';


const OfflineOrder: React.FC = () => {

    const { apiRequest } = useApiRequest();
    const [order, setOrder] = useState<OfflineOrderProps>();
    const [isLoading, setIsLoading] = useState(false);
    const { uid } = useParams()

    const fetchData = async () => {
        try {
            setIsLoading(true)

            const response = await apiRequest({
                route: `${apiUrl}/check-offline-order/${uid}`,
                method: 'GET'
            });

            if (response.status === 200) {
                setOrder(response.data);
                setIsLoading(false)
            } else {
                FrCustomeErrorNorify()
            }

        } catch (error) {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const changeOrderStatus = async (order: any) => {
        try {
            const data = { orderStatus: 'annuler' };

            if (order.orderStatus === 'pending') {
                const response = await apiRequest({
                    route: `${apiUrl}/offline_orders/${order.id}`,
                    method: 'PUT',
                    data: data
                });
                if (response.status === 200) {
                    fetchData();
                    notify('Commande bien annuler')
                } else {
                    FrCustomeErrorNorify()
                }
            }

        } catch (error) {
            FrCustomeErrorNorify()
        }
    };

    const rePay = async (o: OfflineOrderProps) => {
        // ----------- Start CMI Form
        const cmiForm = new FormData()
        const billToStreet = `${o.adresse}, ${o.ville}, ${o.codepostal}`

        cmiForm.append('BillToName', o.fullName)
        cmiForm.append('BillToStreet', billToStreet)
        cmiForm.append('totalPrice', String(o.totalPrice))
        cmiForm.append('reference', String(o.reference))
        cmiForm.append('itemsQuantity', String(o.itemsQuantity))
        cmiForm.append('email', String(o.email))
        cmiForm.append('telephone', String(o.telephone))
        // ----------- End CMI Form

        // Start CMI Request
        const cmiResponse = await apiRequest({
            route: `${apiUrl}/offline/payments/cmi`,
            method: 'POST',
            data: cmiForm
        });
        if (cmiResponse.status === 200) {
            const { url, data } = cmiResponse.data;
            const form = document.getElementById(
                "cmiRePaymentForm"
            ) as HTMLFormElement;
            form.method = "POST";
            form.action = url;
            Object.keys(data).forEach((name) => {
                var input = document.createElement("input");
                input.name = name;
                input.value = data[name];
                form.appendChild(input);
            });

            form.submit();

        } else {
            FrCustomeErrorNorify()
        }
        // End CMI Request
    }

    return (
        <Layout>
            <Helmet>
                <title>Commandes</title>
            </Helmet>
            <div className="container">
                {isLoading ?
                    <AnimatedLoading label='Des Commandes' className='col-12' />
                    :
                    <>
                        <div className="row mt-3rem">
                            <div className="col-12 mbs-ms-4 newold">
                                <div className="account-order ms-4 mbs-me-3">

                                    {order ?
                                        <div className="order-content edit-form">

                                            <table className='table align-middle gs-0 gy-4'>
                                                <thead>
                                                    <tr className='fw-bold text-muted bg-light'>
                                                        <th className=''>N° CMD</th>
                                                        <th className=''>Passé le</th>
                                                        <th className=''>Quantity</th>
                                                        <th className=''>Total</th>
                                                        <th className=''>M.D.Payment</th>
                                                        <th className=''>Payment Status</th>
                                                        <th className=''>Status</th>
                                                        <th className=' text-end rounded-end pe-5'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        <tr key={order.id}>
                                                            <td>
                                                                <span className='ff-varela-round clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                    {order.reference}
                                                                </span>
                                                            </td>

                                                            <td>
                                                                <span className='ff-varela-round clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                    {formatDate(order.createdAt)}
                                                                </span>
                                                            </td>

                                                            <td>
                                                                <span className='ff-varela-round clr-dark fw-bold text-hover-primary d-block mb-1 fs-6 ms-4'>
                                                                    <span className='clr-dark'>{order.itemsQuantity}</span>
                                                                </span>
                                                            </td>

                                                            <td>
                                                                <span className='ff-varela-round clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                    {order.totalPrice} DH
                                                                </span>
                                                            </td>

                                                            <td>
                                                                <span className='ff-varela-round text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                    {order.paymentMethod === 'livraison' ? 'À la livraison' : 'Carte bancaire'}
                                                                </span>
                                                            </td>

                                                            <td>
                                                                {
                                                                    order.paymentMethod === 'carte' ?
                                                                        <>
                                                                            {order.paymentStatus === 'pending' && (
                                                                                <span className='mbs-badge-primary mbs-badge'>
                                                                                    En attente
                                                                                </span>
                                                                            )}
                                                                            {order.paymentStatus === 'fail' && (
                                                                                <span className='mbs-badge-danger mbs-badge'>
                                                                                    Impayé
                                                                                </span>
                                                                            )}

                                                                            {order.paymentStatus === 'ok' && (
                                                                                <span className='mbs-badge-success mbs-badge'>
                                                                                    Payé
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                        :
                                                                        <span className='mbs-badge-info mbs-badge'>
                                                                            A la livraison
                                                                        </span>
                                                                }
                                                            </td>

                                                            <td>
                                                                <span className='ff-varela-round clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                    {order.orderStatus === 'pending' && (
                                                                        <span className='mbs-badge-primary mbs-badge'>
                                                                            En cours
                                                                        </span>
                                                                    )}
                                                                    {order.orderStatus === 'shipping' && (
                                                                        <span className='mbs-badge-info mbs-badge'>
                                                                            Expédier
                                                                        </span>
                                                                    )}
                                                                    {order.orderStatus === 'annuler' && (
                                                                        <span className='mbs-badge-warning mbs-badge'>
                                                                            Annuler
                                                                        </span>
                                                                    )}

                                                                    {order.orderStatus === 'validate' && (
                                                                        <span className='mbs-badge-success mbs-badge'>
                                                                            Validé
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </td>

                                                            <td className='text-end d-flex justify-content-end'>

                                                                {order.orderStatus === 'pending' ?
                                                                    order.paymentStatus !== 'ok' ?
                                                                        <button
                                                                            className='mbs-danger-in-btn btn ms-2 px-3'
                                                                            onClick={() => changeOrderStatus(order)}
                                                                        >
                                                                            Annuler
                                                                        </button>
                                                                        :
                                                                        // <span className='mbs-badge-success mbs-badge ms-2 d-flex-center'>
                                                                        //     Déjà Payé
                                                                        // </span>
                                                                        null
                                                                    :
                                                                    // <span className='mbs-badge-info mbs-badge ms-2 d-flex-center'>
                                                                    //     Déjà Annulé
                                                                    // </span>
                                                                    null
                                                                }

                                                                {order.paymentMethod === 'carte' && order.paymentStatus !== 'ok' ?
                                                                    <div className="pay-loader">
                                                                        <DottedLoading />
                                                                    </div>
                                                                    :
                                                                    order.orderStatus === 'annuler' ?
                                                                        null
                                                                        :
                                                                        <button
                                                                            className='mbs-success-btn btn ms-2'
                                                                            onClick={() => rePay(order)}>
                                                                            Re-Paiement
                                                                        </button>


                                                                }

                                                                <Link to={`order-details/${order.id}`} className="details-vie btn mbs-small-btn mbs-info-btn ms-2  d-flex-center">
                                                                    <AiOutlineEye className="mbs-icon mbs-info-icon" />
                                                                </Link>
                                                            </td>
                                                        </tr>


                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        ''
                                    }

                                    {!order ?
                                        <div className="order-message d-flex-center">
                                            <span>
                                                <Link to='/boutique' className='nav-link d-inline goto-boutique'>
                                                    <BsChatRightDots className='order-message-icon' /> PARCOURIR LES PRODUITS
                                                </Link>
                                                <span className='account-notif'> | Aucune commande n'a encore été passée.</span>
                                            </span>
                                        </div>
                                        :
                                        ''
                                    }

                                </div>
                            </div >
                        </div>
                        <div className="row mt-3rem">
                            <div className="col-12 mbs-ms-4 newold">
                                <div className="account-order ms-4 mbs-me-3">

                                    {order ?
                                        <div className="order-content edit-form">
                                            <table className='table align-middle gs-0 gy-4'>
                                                <thead>
                                                    <tr className='fw-bold text-muted bg-light'>
                                                        <th className='ps-4 rounded-start'>Image</th>
                                                        <th className=' rounded-start'>Nom Produit</th>
                                                        <th className='text-center'>Quantité</th>
                                                        <th className='text-center'>Prix</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        order.offlineOrderItems.map((o) => (
                                                            <tr key={o.id}>
                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='symbol symbol-50px'>
                                                                            <img
                                                                                src={`${uploadUrl}/${o.product
                                                                                    ?
                                                                                    `product_images/${o.product?.image}`
                                                                                    :
                                                                                    `pack_images/${o.pack?.image}`}`}
                                                                                className='order-details-img'
                                                                                alt={o.product?.title}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className='td-min-w'>
                                                                    <span className='ff-varela-round text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {`${o.product ? o.product?.title : o.pack?.title}`}
                                                                    </span>
                                                                </td>

                                                                <td className='text-center'>
                                                                    <span className='ff-varela-round clr-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {o.quantity}
                                                                    </span>
                                                                </td>

                                                                <td className='text-center'>
                                                                    <span className='ff-varela-round clr-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {o.product ? o.product?.sellingPrice : o.pack?.sellingPrice} DH
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>

                                        </div>
                                        :
                                        ''
                                    }

                                </div>
                            </div>
                        </div>
                    </>

                }
                <div className='d-none'>
                    <form name='cmiRePaymentForm' id="cmiRePaymentForm"></form>
                </div>
            </div>
        </Layout>
    );
};

export default OfflineOrder