import './App.css';
import './assets/sass/App.scss';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Routing } from "./app/routing/Routing";
import { QueryClient, QueryClientProvider } from 'react-query';
import { WindowWidthProvider } from "@contexts/WindowWidthContext";
import { HelmetProvider } from "react-helmet-async";
import { PackProvider } from "@contexts/PackContext";
import { ShoppingCartProvider } from "@contexts/ShoppingCartContext";
import { ShoppingCartDataProvider } from "@contexts/ShoppingCartDataContext";
import { BackToTop } from "@components/BackToTop/BackToTop";
import { CategoriesProvider } from '@contexts/CategoriesContext';
import { MarquesProvider } from '@contexts/MarquesContext';
import { SlidersProvider } from '@contexts/SliderContext';
import { OrderInfoProvider } from '@contexts/OrderInfoContext';

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <WindowWidthProvider>
        <SlidersProvider>
          <CategoriesProvider>
            <PackProvider>
              <MarquesProvider>
                <ShoppingCartProvider>
                  <ShoppingCartDataProvider>
                    <OrderInfoProvider>
                      <HelmetProvider>
                        <ToastContainer limit={5} />
                        <Routing />
                        <BackToTop />
                      </HelmetProvider>
                    </OrderInfoProvider>
                  </ShoppingCartDataProvider>
                </ShoppingCartProvider>
              </MarquesProvider>
            </PackProvider>
          </CategoriesProvider>
        </SlidersProvider>
      </WindowWidthProvider>
    </QueryClientProvider>
  );
};

export default App;