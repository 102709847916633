import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { SliderProps } from '@helpers/Props';
import useApiRequest from '@mbs-dev/api-request';
import { apiUrl } from '@helpers/Helpers';

const SliderContext = createContext<{
    sliders: SliderProps[];
    isFetching: boolean;
}>({
    sliders: [],
    isFetching: false,
});

export const useSliderContext = () => useContext(SliderContext);

export const SlidersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { apiRequest } = useApiRequest()

    const {
        data,
        isLoading: isFetching,
    } = useQuery<SliderProps[], Error>(
        'sliders',
        async () => {
            const response = await apiRequest({
                route: `${apiUrl}/sliders.json`,
                method: 'GET',
            });

            return response.data;

        }
    );

    const sliders = data || []


    return (
        <SliderContext.Provider value={{
            sliders,
            isFetching: isFetching
        }}>
            {children}
        </SliderContext.Provider>
    );
};
