import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './SubCategorySwiper.css'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Link } from "react-router-dom";
import { CategorieProps } from "@helpers/Props";
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import { categorieImgUrl, handleImageLinkDrage } from '@helpers/Helpers';
import { useState } from 'react';

interface SubCategorySwiperProps {
    subCategories: CategorieProps[];
    categorie: CategorieProps;
}

const SubCategorySwiper: React.FC<SubCategorySwiperProps> = ({ subCategories, categorie }) => {
    const { windowWidth } = useWindowWidthContext()
    const [style, setStyle] = useState<string>('')

    // let screen = 9

    // if (windowWidth < 1600 && windowWidth >= 1430) {
    //     screen = 9
    // } else if (windowWidth < 1430 && windowWidth >= 1300) {
    //     screen = 7
    // } else if (windowWidth < 1300 && windowWidth >= 1200) {
    //     screen = 8
    // } else if (windowWidth < 1200 && windowWidth >= 992) {
    //     screen = 7
    // } else if (windowWidth < 992 && windowWidth >= 768) {
    //     screen = 6
    // } else if (windowWidth < 768 && windowWidth >= 578) {
    //     screen = 5
    // } else if (windowWidth < 578 && windowWidth >= 500) {
    //     screen = 4
    // } else if (windowWidth < 500) {
    //     screen = 3
    // }
    if (subCategories.length > 0) {
        setTimeout(() => {
            setStyle('subcategory-navigation')
        }, 2000);
    }

    let screen = 7

    if (windowWidth >= 1430) {
        screen = 7
    } else if (windowWidth < 1430 && windowWidth >= 1300) {
        screen = 5
    } else if (windowWidth < 1300 && windowWidth >= 1200) {
        screen = 5
    } else if (windowWidth < 1200 && windowWidth >= 992) {
        screen = 5
    } else if (windowWidth < 992 && windowWidth >= 768) {
        screen = 4
    } else if (windowWidth < 768 && windowWidth >= 578) {
        screen = 4
    } else if (windowWidth < 578 && windowWidth >= 500) {
        screen = 4
    } else if (windowWidth < 500) {
        screen = 4
    }

    return (
        <div className='boutique mb-5 mt-3'>
            <div className="container">
                <div className={`row justify-content-center ${windowWidth < 768 ? style : 'subcategory-navigation'}`}>
                    <Swiper
                        // centeredSlidesBounds
                        // centerInsufficientSlides
                        className="mySwiper"
                        loop={true}
                        centeredSlides
                        slidesPerView={screen}
                        // rewind
                        modules={[Autoplay, Pagination, Navigation]}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={false}
                    // navigation

                    >
                        {subCategories.map((item: CategorieProps, index) => (
                            <SwiperSlide key={item.id}>
                                <div className="subcategory-swiper-content c-pointer d-flex-center mxc-5" key={item.id}>
                                    <div className="subcategory-swiper d-flex-center radius-10px o-hidden p-2px">
                                        <Link to={`/boutique/categorie/${categorie.slug}/${item.slug}`} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100'>
                                            <img
                                                src={`${categorieImgUrl}/${item?.image}`}
                                                className="subcategory-swiper-img radius-10px"
                                                alt={item?.title}
                                                onDragStart={handleImageLinkDrage}
                                            />
                                        </Link>

                                    </div>

                                    <div className="d-none subcategory-swiper-title mt-3 d-non align-items-center justify-content-center">
                                        <p className='subcategory-swiper-title ff-0 fw-600'>{item?.title}</p>
                                    </div>

                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default SubCategorySwiper;