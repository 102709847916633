import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorNotify, notify } from '@helpers/Toastify';
import { formHelper, FormInput, HeaderContainer, SubmitButton, loadingHelper } from '@helpers/Index';
import { ReactHelmet, apiUrl } from '@helpers/Helpers';
import { initResetPassword } from '@helpers/FormikHelper';
import { resetPasswordSchema } from '@helpers/YupHelper';
import Layout from '../../layouts/Layout';
import './LoginStyle.css'
import useApiRequest from '@mbs-dev/api-request';

const ResetPassword: FC = () => {

  // Global
  const navigate = useNavigate();
  const mbs_user_token = localStorage.getItem('mbs_user_token');
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const { apiRequest } = useApiRequest()

  useEffect(() => {
    if (mbs_user_token) {
      navigate('/my-account');
    }
  }, [mbs_user_token]);


  const formik = formHelper.useFormik({
    initialValues: initResetPassword,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('password', values.password);

        const response = await apiRequest({
          route: `${apiUrl?.replace('/api','')}/reset_password/${token}`,
          method: 'POST',
          data: formData
        });

        if (response.status === 200) {
          setIsLoading(false)
          navigate('/login');
          notify(await response.data.message);
        }

      } catch (error) {
        ErrorNotify("Aucun d'utilisateur trouvé avec l'adresse e-mail !");
      }
    },
  });


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <Layout>
      <ReactHelmet title='Réinitialiser le mot de passe' />
      <HeaderContainer
        className="fw-bold fs-1 mt-5"
        title='Réinitialiser le mot de passe'
      />
      <div className="container d-flex-center pt-5">

        <form onSubmit={handleSubmit} className='d-flex-center'>
          <div className="login-form">
            <div className="row d-flex-start">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <FormInput
                  label="Mot de passe"
                  spanValue="Mot de passe"
                  name="password"
                  type="password"
                  value={values.password}
                  className={touched.password && errors.password ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <FormInput
                  label="Confirmer le mot de passe"
                  spanValue="Confirmer le mot de passe"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  className={touched.confirmPassword && errors.confirmPassword ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-5 d-flex-start">
                {isLoading ?
                  <loadingHelper.DottedLoading />
                  :
                  <SubmitButton
                    className="mt-1 px-2rem py-14px"
                    btnLabel="Réinitialiser"
                  />
                }

              </div>

            </div>

          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ResetPassword;