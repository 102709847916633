import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useShoppingCart } from '@contexts/ShoppingCartContext';
import { HeaderContainer } from '@helpers/Index';
import Icons from '@helpers/IconsHelper';
import Layout from '@layouts/Layout';
import './UserDashStyle.css'

interface UserDashboardProps {
    secondComponent: React.FC;
}

const UserDashboard: React.FC<UserDashboardProps> = ({ secondComponent: SecondComponent }) => {
    const mbs_user_token = localStorage.getItem('mbs_user_token');
    const { emptyShoppingCart } = useShoppingCart();
    const navigate = useNavigate();
    useEffect(() => {
        if (!mbs_user_token) {
            navigate('/login');
        }
    }, [mbs_user_token, navigate]);

    const logout = () => {
        emptyShoppingCart();
        localStorage.removeItem('mbs_user_token');
        localStorage.removeItem('mbs_user');
        navigate('/login');
    }

    return (
        <Layout>
            {/* <HeaderContainer className="fw-bold mt-5 mb-5 pb-4 mon-compte-title" title="Mon compte" /> */}

            <div className="user-dash mb-3rem mt-2rem">
                <div className="productcontainer container d-flexd justify-content-center">
                    <div className="row d-flex justify-content-center">

                        <div className="col-11 col-md-4 col-lg-3 col-xl-3 col-xxl-2 me-5s me-md-1 me-lg-1 mb-5 pb-4 user-account">
                            <div className="container mt-5">

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/my-account' className='nav-link'>
                                                <Icons.RxDashboard className='mb-1 me-3 dash-icons' />
                                                <span>TABLEAU DE BORD</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/my-account/orders' className='nav-link'>
                                                <Icons.FiShoppingBag className='mb-1 me-3 dash-icons' />
                                                <span>COMMANDES</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/my-account/reviews' className='nav-link'>
                                                <Icons.MdOutlineRateReview className='mb-1 me-3 dash-icons' />
                                                <span>Avis</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/my-account/edit-address' className='nav-link'>
                                                <Icons.IoMdLocate className='mb-1 me-3 dash-icons' />
                                                <span>ADRESSES</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/my-account/edit-account' className='nav-link'>
                                                <Icons.MdOutlineManageAccounts className='mb-1 me-3 dash-icons' />
                                                <span>DÉTAILS DU COMPTE</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                <div className="row">
                                    <div className="user-dash-title ">

                                        <div className="user-dash-row ">
                                            <button onClick={() => logout()} className='btn nav-link'>
                                                <Icons.FiLogOut className='mb-1 me-3 dash-icons' />
                                                <span>DÉCONNEXION</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                            </div>
                        </div>

                        <SecondComponent />

                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default UserDashboard;