import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserDashboard from '../UserDashboard/UserDashboard';
import { BsChatRightDots } from 'react-icons/bs'
import { AuthUserProps, OrderProps } from '@helpers/Props';
import useApiRequest from '@mbs-dev/api-request';
import { apiUrl, formatDate} from '@helpers/Helpers';
import { AiOutlineEye } from 'react-icons/ai';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { FrCustomeErrorNorify } from '@helpers/Toastify';
import AnimatedLoading from '@components/Loading/AnimatedLoading';
import DottedLoading from '@components/Loading/DottedLoading';
import { Pagination } from '@mui/material'
import './OrdersStyle.css'
import { useQuery } from 'react-query';

const Content: React.FC = () => {

    const [orders, setOrders] = useState<OrderProps[]>([]);
    const { apiRequest } = useApiRequest();

    const [user, setUser] = useState<AuthUserProps | null>(null);
    // const [isLoading, setIsLoading] = useState(false);
    const [clickedRow, setClickedRow] = useState<number | null>(null);
    // Pagination state
    const [isFilter, setIsFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [filterCurrentPage, setFilterCurrentPage] = useState(1)
    const [filterTotalPages, setFilterTotalPages] = useState(1)
    const [inputFilter, setInputFilter] = useState(false)
    const [filterTotalItems, setFilterTotalItems] = useState(0)

    useEffect(() => {
        const storedUser = localStorage.getItem('mbs_user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);


    const fetchLdDataa = async () => {
        try {
            if (user?.id) {
                // setIsLoading(true)

                const response = await apiRequest({
                    route: `${apiUrl}/orders.jsonld`,
                    method: 'GET',
                    params: {
                        page: currentPage,
                        itemsPerPage: 10,
                        'user.id': user?.id,
                    },
                    headers: {
                        Accept: 'application/ld+json'
                    },
                    requiresAuth: true,
                    token: `${localStorage.getItem('mbs_user_token')}`
                });

                if (response.status === 200) {
                    setOrders(response.data['hydra:member']);
                    setTotalItems(response.data['hydra:totalItems']);
                    setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
                    // setIsLoading(false)
                } else {
                    FrCustomeErrorNorify()
                }
            }

        } catch (error) {
            FrCustomeErrorNorify()
            // setIsLoading(false)
        }
    }

    // useEffect(() => {
    //     fetchLdData();
    // }, [user?.id, currentPage, totalItems])

    const fetchLdData = async (userId: string | number | undefined, currentPage: number) => {
        if (!userId) {
            return; // Handle case where user ID is not available
        }

        // setIsLoading(true);

        try {
            const response = await apiRequest({
                route: `${apiUrl}/orders.jsonld`,
                method: 'GET',
                params: {
                    page: currentPage,
                    itemsPerPage: 10,
                    'user.id': userId,
                },
                headers: {
                    Accept: 'application/ld+json',
                },
                requiresAuth: true,
                token: `${localStorage.getItem('mbs_user_token')}`,
            });

            if (response.status === 200) {
                setOrders(response.data['hydra:member']);
                setTotalItems(response.data['hydra:totalItems']);
                setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
            } else {
                FrCustomeErrorNorify();
            }
        } catch (error) {
            FrCustomeErrorNorify();
        } finally {
            // setIsLoading(false);
        }
    };

    const { isLoading, error, data } = useQuery(
        ['orders', user?.id, currentPage],
        () => fetchLdData(user?.id, currentPage),
        {
            enabled: !!user?.id,
        }
    );


    const handlePageChange = (event: any, value: any) => {
        setCurrentPage(value);
    }

    const changeOrderStatus = async (order: any) => {
        try {
            const data = { orderStatus: 'annuler' };

            if (order.orderStatus === 'pending') {
                const response = await apiRequest({
                    route: `${apiUrl}/orders/${order.id}`,
                    method: 'PUT',
                    data: data,
                    requiresAuth: true,
                    token: `${localStorage.getItem('mbs_user_token')}`
                });
                if (response.status === 200) {
                    fetchLdData(user?.id, currentPage);
                    toast.success("Commande bien annuler");
                } else {
                    FrCustomeErrorNorify()
                }
            }

        } catch (error) {
            FrCustomeErrorNorify()
        }
    };

    const rePay = async (o: OrderProps, rowIndex: number) => {
        // ----------- Start CMI Form
        setClickedRow(rowIndex);
        const cmiForm = new FormData()
        const billToStreet = `${o.facturationAdresse.rueInfo}, ${o.facturationAdresse.ville}, ${o?.facturationAdresse.region}`

        cmiForm.append('user', String(o.user.id))
        cmiForm.append('BillToStreet', billToStreet)
        cmiForm.append('totalPrice', String(o.totalPrice))
        cmiForm.append('reference', String(o.reference))
        cmiForm.append('itemsQuantity', String(o.itemsQuantity))
        cmiForm.append('email', String(o.facturationAdresse.email))
        cmiForm.append('telephone', String(o.facturationAdresse.telephone))
        // ----------- End CMI Form

        // Start CMI Request
        const cmiResponse = await apiRequest({
            route: `${apiUrl}/payments/cmi`,
            method: 'POST',
            data: cmiForm,
            requiresAuth: true,
            token: `${localStorage.getItem('mbs_user_token')}`
        });
        if (cmiResponse.status === 200) {
            const { url, data } = cmiResponse.data;
            const form = document.getElementById(
                "cmiRePaymentForm"
            ) as HTMLFormElement;
            form.method = "POST";
            form.action = url;
            Object.keys(data).forEach((name) => {
                var input = document.createElement("input");
                input.name = name;
                input.value = data[name];
                form.appendChild(input);
            });

            form.submit();

        } else {
            FrCustomeErrorNorify()
        }
        // End CMI Request
    }

    // col-12 col-lg-8 col-md-7 col-xl-8 mbs-ms-4
    return (
        <>
            <Helmet>
                <title>Commandes</title>
            </Helmet>
            <div className="col-12 col-lg-7 col-md-7 col-xl-8 col-xxl-9 mbs-ms-4 newold">

                {

                    isLoading ?
                        <AnimatedLoading label='Des Commandes' className='col-12' />
                        :
                        <div className="account-order ms-4 mbs-me-3">

                            {
                                orders.length > 0 ?

                                    <div className="order-content edit-form">

                                        <table className='table align-middle gs-0 gy-4'>
                                            <thead>
                                                <tr className='fw-bold text-muted bg-light'>
                                                    <th className=''>N° CMD</th>
                                                    <th className=''>Passé le</th>
                                                    <th className=''>Quantity</th>
                                                    <th className=''>Total</th>
                                                    <th className=''>M.D.Payment</th>
                                                    <th className=''>Payment Status</th>
                                                    <th className=''>Status</th>
                                                    <th className=' text-end rounded-end pe-5'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orders.map((item, index) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <span className='ff-0 clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {item.reference}
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span className='ff-0 clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {formatDate(item.createdAt)}
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span className='ff-0 clr-dark fw-bold text-hover-primary d-block mb-1 fs-6 ms-4'>
                                                                        <span className='clr-dark'>{item.itemsQuantity}</span>
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span className='ff-0 clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {item.totalPrice} DH
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span className='ff-0 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {item.paymentMethod === 'livraison' ? 'À la livraison' : 'Carte bancaire'}
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    {
                                                                        item.paymentMethod === 'carte' ?
                                                                            <>
                                                                                {item.paymentStatus === 'pending' && (
                                                                                    <span className='mbs-badge-primary mbs-badge'>
                                                                                        En attente
                                                                                    </span>
                                                                                )}
                                                                                {item.paymentStatus === 'fail' && (
                                                                                    <span className='mbs-badge-danger mbs-badge'>
                                                                                        Impayé
                                                                                    </span>
                                                                                )}

                                                                                {item.paymentStatus === 'ok' && (
                                                                                    <span className='mbs-badge-success mbs-badge'>
                                                                                        Payé
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                            :
                                                                            <span className='mbs-badge-info mbs-badge'>
                                                                                A la livraison
                                                                            </span>
                                                                    }
                                                                </td>

                                                                <td>
                                                                    <span className='ff-0 clr-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                        {item.orderStatus === 'pending' && (
                                                                            <span className='mbs-badge-primary mbs-badge'>
                                                                                En cours
                                                                            </span>
                                                                        )}
                                                                        {item.orderStatus === 'shipping' && (
                                                                            <span className='mbs-badge-info mbs-badge'>
                                                                                Expédier
                                                                            </span>
                                                                        )}
                                                                        {item.orderStatus === 'annuler' && (
                                                                            <span className='mbs-badge-warning mbs-badge'>
                                                                                Annuler
                                                                            </span>
                                                                        )}

                                                                        {item.orderStatus === 'validate' && (
                                                                            <span className='mbs-badge-success mbs-badge'>
                                                                                Validé
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                </td>

                                                                <td className='text-end d-flex justify-content-end'>

                                                                    {item.orderStatus === 'pending' ?
                                                                        item.paymentStatus !== 'ok' ?
                                                                            <button
                                                                                className='mbs-danger-in-btn btn ms-2 px-3'
                                                                                onClick={() => changeOrderStatus(item)}
                                                                            >
                                                                                Annuler
                                                                            </button>
                                                                            :
                                                                            // <span className='mbs-badge-success mbs-badge ms-2 d-flex-center'>
                                                                            //     Déjà Payé
                                                                            // </span>
                                                                            null
                                                                        :
                                                                        // <span className='mbs-badge-info mbs-badge ms-2 d-flex-center'>
                                                                        //     Déjà Annulé
                                                                        // </span>
                                                                        null
                                                                    }

                                                                    {
                                                                        item.paymentMethod === 'carte' && item.paymentStatus !== 'ok' ?
                                                                            clickedRow === index ?
                                                                                <div className="pay-loader">
                                                                                    <DottedLoading />
                                                                                </div>
                                                                                :
                                                                                item.orderStatus === 'annuler' ?
                                                                                    null
                                                                                    :
                                                                                    <button
                                                                                        className='mbs-success-btn btn ms-2'
                                                                                        onClick={() => rePay(item, index)}>
                                                                                        Re-Paiement
                                                                                    </button>

                                                                            : null
                                                                    }

                                                                    <Link to={`order-details/${item.id}`} state={item} className="details-vie btn mbs-small-btn mbs-info-btn ms-2  d-flex-center">
                                                                        <AiOutlineEye className="mbs-icon mbs-info-icon" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })

                                                }
                                            </tbody>
                                        </table>
                                        <div className="row d-flex-center">
                                            <Pagination
                                                count={totalPages}
                                                page={currentPage}
                                                color='primary'
                                                onChange={handlePageChange}
                                                className='d-flex justify-content-center'
                                            />
                                        </div>
                                    </div>
                                    :
                                    ''
                            }

                            {

                                orders.length === 0 ?
                                    <div className="order-message d-flex-center">
                                        <span>
                                            <Link to='/boutique' className='nav-link d-inline goto-boutique'>
                                                <BsChatRightDots className='order-message-icon' /> PARCOURIR LES PRODUITS
                                            </Link>
                                            <span className='account-notif'> | Aucune commande n'a encore été passée.</span>
                                        </span>
                                    </div>
                                    :

                                    ''
                            }

                        </div>
                }

            </div >
            <div className='d-none'>
                <form name='cmiRePaymentForm' id="cmiRePaymentForm"></form>
            </div>

        </>
    );
};


const UserOrders: React.FC = () => {

    return (
        <>
            <UserDashboard secondComponent={Content} />
        </>
    );
};

export default UserOrders;