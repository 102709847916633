import { useEffect, useState } from 'react'
import { SelectedSnapDisplay, useSelectedSnapDisplay } from './EmblaCarouselSelectedSnapDisplay'
import useEmblaCarousel from 'embla-carousel-react'
import { OfferCarouselProps, SpecialOffersProps } from '@helpers/Props'
import { Link } from 'react-router-dom'
import { specialOffersImgUrl } from '@helpers/Helpers'

import './SpecialOfferCarousel.css'


const SpecialOfferCarousel: React.FC<OfferCarouselProps> = (props) => {
    const { slides, options, isLoading } = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options)

    const { selectedSnap, snapCount } = useSelectedSnapDisplay(emblaApi)


    return (
        <>
            {!isLoading ?
                <div className="special-offers container mt-2rem">
                    <section className="embla">
                        <div className="embla__viewport" ref={emblaRef}>
                            <div className="embla__container">
                                {slides.map((item, index) => (
                                    item.todayOffer === 'disable' ?
                                        <div className="embla__slide" key={index}>
                                            <Link key={item.id} to={item.product !== undefined ? `/boutique/product/${item.slug}` : `/boutique/categorie/${item.slug}`} className="nav-link">
                                                <img
                                                    className="embla__slide-img radius-10px"
                                                    src={`${specialOffersImgUrl}/${item.image}`}
                                                    alt={item.title}
                                                />
                                            </Link>
                                        </div>
                                        :
                                        null
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
                :
                null
            }
        </>
    )
}

export default SpecialOfferCarousel
