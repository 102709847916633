import React from 'react'
import TopHeader from '@components/TopHeader/TopHeader'
import Footer from "@components/Footer/Footer";
import HomeNavbar from '@components/Navbar/HomeNavbar';
import { Helmet } from 'react-helmet-async';
import { CategorieProps, LayoutProps } from '@helpers/Props';
import { useCategoriesContext } from '@contexts/CategoriesContext';


const Layout: React.FC<LayoutProps> = ({ children, containerTitle, className, footerClass }) => {
    const { categories } = useCategoriesContext()

    return (
        <React.Fragment>
            <Helmet>
                <title>Rose Et Bleu: Votre Boutique en Ligne pour Bébés et Enfants</title>
                <meta name="description" content="Découvrez une large gamme de produits pour bébés et enfants chez Rose Et Bleu. Trouvez des vêtements adorables, des jouets éducatifs, des articles de puériculture et bien plus encore. Livraison rapide et service client dévoué." />
                <meta name="keywords" content={`Rose & Bleu, Rose&Bleu, Rose Et Bleu, boutique bébé, vêtements enfants, jouets éducatifs, articles puériculture, cadeaux naissance, accessoires bébés, mode enfantine, décorations chambre enfants, chaussures enfants, puériculture pratique, jeux éducatifs, bébés filles, bébés garçons, filles vêtements, garçons vêtements, cadeaux anniversaire enfant, jouets créatifs, doudous enfants, baby supplies, baby clothes, baby toys, baby gifts, children's supplies, children's clothes, children's toys, children's gifts, girl, boy, childcare, ${categories.map((item: CategorieProps) => (`${item.title}`))}`} />
                <meta name="author" content="Rose Et Bleu" />
                {/* <meta name="robots" content="index, follow" /> */}
                <meta property="og:image" content="%PUBLIC_URL%/media/logo/logo-rb.png" />
                <meta property="og:url" content="https://rose-bleu.ma" />
                <meta property="og:title" content="Rose Et Bleu: Votre Boutique en Ligne pour Bébés et Enfants" />
                <meta property="og:description" content="Découvrez une large gamme de produits pour bébés et enfants chez Rose Et Bleu. Trouvez des vêtements adorables, des jouets éducatifs, des articles de puériculture et bien plus encore. Livraison rapide et service client dévoué." />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Rose Et Bleu" />
                <meta property="og:locale" content="fr_FR" />
            </Helmet>

            <TopHeader />
            <HomeNavbar />
            <main>{children}</main>
            <Footer />
        </React.Fragment>
    )
}

export default Layout