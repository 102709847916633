import { useEffect, useState } from 'react'
import { SelectedSnapDisplay, useSelectedSnapDisplay } from './EmblaCarouselSelectedSnapDisplay'
import { OfferCarouselProps, SpecialOffersProps } from '@helpers/Props'
import { Link } from 'react-router-dom'
import { specialOffersImgUrl } from '@helpers/Helpers'
import useEmblaCarousel from 'embla-carousel-react'

import './TodayOfferCarousel.css'


const TodayOfferCarousel: React.FC<OfferCarouselProps> = (props) => {
    const { slides, options, isLoading } = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options)
    // const [todayOffers, setTodayOffers] = useState<SpecialOffersProps[]>([])
    // useEffect(() => {
    //     setTodayOffers(slides.filter(item => item.todayOffer === 'enable'))
    // }, [slides])

    const { selectedSnap, snapCount } = useSelectedSnapDisplay(emblaApi)


    return (
        <>
            {!isLoading ?
                <div className="todays-offers container mt-2rem">
                    <section className="embla">
                        <div className="embla__viewport" ref={emblaRef}>
                            <div className="embla__container">
                                {slides.filter(item => item.todayOffer === 'enable').map((item, index) => (
                                    <div className="todayo-ffer embla__slide" key={index}>
                                        <Link key={item.id} to={`/boutique/product/${item.slug}`} className="nav-link">
                                            <img
                                                className="embla__slide-img radius-10px"
                                                src={`${specialOffersImgUrl}/${item.image}`}
                                                alt={item.title}
                                            />
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
                :
                null
            }
        </>
    )
}

export default TodayOfferCarousel
