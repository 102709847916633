import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { useCategoriesContext } from '@contexts/CategoriesContext';
import { FooterLink } from '@helpers/Helpers';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import NewsEmail from '../NewsEmail/NewsEmail';
import Icons from '@helpers/IconsHelper';
import './FooterStyle.css'

const Footer: FC = () => {
  const { categories } = useCategoriesContext();
  const [showCategorie, setShowCategorie] = useState(false);
  const [showIndfo, setShowIndfo] = useState(false);
  const { windowWidth } = useWindowWidthContext()


  return (
    <>
      {categories.length > 0 ?
        <MDBFooter className={` text-center text-lg-start text-muted bg-footer ff-montserrat-reg clr-pink ff-montserrat-reg00 mt-3rem`}>
          <section className=''>
            <MDBContainer className='text-center text-md-start mt5 pt-2'>
              <MDBRow className='mt-3'>
                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                  <h6 className='text-uppercase clr-pink ff-montserrat-reg mb-4'>
                    {/* <MDBIcon color='secondary' icon='gem' className='' /> */}
                    Rose Et Bleu
                  </h6>
                  <p className='d-non ff-varela-round'>
                    Inscrivez-vous
                    {/* # */}
                  </p>
                  <NewsEmail />

                </MDBCol>

                <MDBCol md='2' lg='2' xl='2' className={`mx-auto mb-${windowWidth > 640 ? '4' : '2'}`}>
                  <h6 className={`text-uppercase clr-pink ff-montserrat-reg mb-${windowWidth > 640 ? '4' : '2'} c-pointer`} onClick={() => {
                    setShowCategorie(!showCategorie)
                    setShowIndfo(false)
                  }}>CATÉGORIES  {windowWidth > 578 ? '' : <Icons.MdExpandMore className='clr-dark pb-1 fs-25px' />}</h6>
                  {windowWidth > 578 || showCategorie ?
                    categories.map((item) => (
                      <p key={item.id} className='mb-1'>
                        <Link to={`/boutique/categorie/${item.slug}`} className='ff-montserrat-reg textpink-hover nav-link'>
                          {item.title}
                        </Link>
                      </p>
                    ))
                    :
                    null
                  }
                </MDBCol>

                <MDBCol md='3' lg='2' xl='2' className={`mx-auto mb-${windowWidth > 640 ? '4' : '2'}`}>
                  <h6 className={`text-uppercase clr-pink ff-montserrat-reg mb-${windowWidth > 640 ? '4' : '2'} c-pointer`} onClick={() => {
                    setShowIndfo(!showIndfo)
                    setShowCategorie(false)
                  }}>Informations {windowWidth > 578 ? '' : <Icons.MdExpandMore className='clr-dark pb-1 fs-25px' />}</h6>

                  {windowWidth > 578 || showIndfo ?
                    <>
                      {/* <FooterLink to='/blogs' label='Blogs' /> */}
                      {/* <FooterLink to='/boutique/packs' label='Packs' /> */}
                      <FooterLink to='/about-us' label='A propos de Rose Et Bleu' />
                      {/* <FooterLink to='/conditions-generales' label='Condition générales' /> */}
                      {/* <FooterLink to='/livraison' label='Livraison' /> */}
                      {/* <FooterLink to='/contact' label='Contact' /> */}
                    </>
                    :
                    null
                  }
                </MDBCol>

                <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                  <h6 className='text-uppercase clr-pink ff-montserrat-reg mb-4'>Contact</h6>
                  <p>
                    <Icons.FaLocationDot className='text-dark mb-1 me-1' />
                    bd l'independance, Residence Decor Dari, Berkane
                  </p>
                  <Link to='mailto:info@rose-bleu.ma' className='mbs-link d-block mb-3'>
                    <Icons.MdEmail className='text-dark mb-1 me-1' />
                    info@rose-bleu.ma
                  </Link>
                  <Link to='tel:0652701475' className='mbs-link d-block mb-1'>
                    <Icons.ImPhone className='text-dark mb-1 me-1' />
                    0658-716457
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>

          <div className={`text-center text-light p-3 copy-right-bg`}>
            © {new Date().getFullYear()} Copyright
            <Link className='text-reset ps-1' to='/'>
              Rose Et Bleu
            </Link>
          </div>
        </MDBFooter>
        : null}
    </>
  );
};

export default Footer;