import { useEffect, useState } from 'react'
import { OrderItem } from '@components/OrderItem/OrderItem'
import { useNavigate } from 'react-router-dom'
import useApiRequest from '@mbs-dev/api-request';
import { FormatDate, ReactHelmet, appUrl, randomKeyGenerator, handleScrollTop, referenceGenerator, apiUrl } from '@helpers/Helpers'
import { AddingNotify, FrCustomeErrorNorify, InfoNotify } from '@helpers/Toastify'
import { SubmitButton, formHelper, loadingHelper } from '@helpers/Index'
import { initOfflineOrder } from '@helpers/FormikHelper'
import { offlineOrderSchema } from '@helpers/YupHelper'
import { useShoppingCart } from '@contexts/ShoppingCartContext'
import Layout from '../../layouts/Layout'
import './CheckoutStyle.css'

const OfflineCheckout: React.FC = () => {

    const { cartItems, cartQuantity, orderData } = useShoppingCart()
    const [isLoading, setIsLoading] = useState(false)
    localStorage.removeItem('isofflineguarded')
    const { apiRequest } = useApiRequest()
    const navigate = useNavigate()

    useEffect(() => {
        handleScrollTop()
        if (cartQuantity < 1) {
            navigate('/boutique')
            InfoNotify('Votre panier est actuellement vide!')
        }
    }, [])

    const handleError = () => {
        setIsLoading(false)
        FrCustomeErrorNorify()
    }

    const transformData = async (data: any[]) => {
        const packIdsArray = await Promise.all(
            data.map(async (item) => {
                const { product, pack, ...rest } = item
                let uniqId = typeof product === 'string' ? product : null

                if (uniqId !== null) {
                    const response = await apiRequest({
                        route: `${apiUrl}/pack-id/uniq_id/${product}`,
                        method: 'GET'
                    })

                    if (response.status === 200) {
                        return response.data.packId
                    }
                }

                return null
            })
        )

        const transformedData = data.map((item, index) => {
            const { product, variant, isvariant, ...rest } = item
            const packId = typeof product === 'string' ? packIdsArray[index] : null

            const transformedItem: any = {
                ...(typeof product === 'number' ? { product: `api/products/${product}` } : {}),
                ...(isvariant && variant ? { variant: `api/variants/${variant}` } : {}),
                ...(typeof product === 'string' && packId !== null ? { pack: `api/packs/${packId}` } : {}),
                ...(isvariant ? { isvariant: true } : { isvariant: false }),
                ...rest,
            }

            return transformedItem
        })

        return transformedData
    }


    const formik = formHelper.useFormik({
        initialValues: initOfflineOrder,
        validationSchema: offlineOrderSchema,
        onSubmit: async (values) => {

            try {
                setIsLoading(true)

                const checkResponse = await apiRequest({
                    route: `${apiUrl}/last-offline-order`,
                    method: 'GET'
                })

                if (checkResponse.status === 200) {
                    const totalPrice = orderData.totalPrice
                    const itemsQuantity = cartItems.length
                    const paymentMethod = orderData.paymentMethod
                    const orderStatus = 'pending'
                    const paymentStatus = 'pending'
                    const reference = `${referenceGenerator()}${checkResponse.data.lastOrderId + 1}`
                    const uid = `${randomKeyGenerator(24)}`

                    let offlineOrderItems = await transformData(cartItems)

                    const data = {
                        uid: uid,
                        totalPrice,
                        itemsQuantity,
                        paymentMethod,
                        paymentStatus,
                        offlineOrderItems,
                        orderStatus,
                        reference,
                        fullName: values.fullName,
                        email: values.email,
                        telephone: values.telephone,
                        adresse: values.adresse,
                        ville: values.ville,
                        codepostal: Number(values.codepostal),
                        orderNote: values.orderNote,
                        created: FormatDate(new Date())
                    }

                    const emailData = new FormData()
                    emailData.append('email', values.email)
                    emailData.append('reference', reference)
                    emailData.append('appUrl', `${appUrl}/check-offline-order/${uid}`)

                    if (paymentMethod === 'livraison') {
                        try {
                            const response = await apiRequest({
                                route: `${apiUrl}/offline_orders`,
                                method: 'POST',
                                data: data
                            })

                            if (response.status === 201) {
                                AddingNotify('Commmane')
                                navigate('/boutique')
                                setIsLoading(false)

                                apiRequest({
                                    route: `${apiUrl}/offline-order-email`,
                                    method: 'POST',
                                    data: emailData
                                })
                            }

                        } catch {
                            handleError()
                        }

                    } else if (paymentMethod === 'carte') {
                        try {

                            const offlineOrderInfo = {
                                userEmail: values.email,
                                reference: reference
                            }

                            localStorage.setItem('offlineOrderInfo', JSON.stringify(offlineOrderInfo))

                            //----------- Start CMI Form
                            const cmiForm = new FormData()
                            const billToStreet = `${values.adresse}, ${values.ville}, ${values.codepostal}`

                            cmiForm.append('BillToName', values.fullName)
                            cmiForm.append('BillToStreet', billToStreet)
                            cmiForm.append('totalPrice', String(totalPrice))
                            cmiForm.append('reference', String(reference))
                            cmiForm.append('itemsQuantity', String(itemsQuantity))
                            cmiForm.append('email', values.email)
                            cmiForm.append('telephone', values.telephone)
                            //----------- End CMI Form

                            try {
                                const response = await apiRequest({
                                    route: `${apiUrl}/offline_orders`,
                                    method: 'POST',
                                    data: data
                                })

                                if (response.status === 201) {

                                    // Start CMI Request
                                    const cmiResponse = await apiRequest({
                                        route: `${apiUrl}/offline/payments/cmi`,
                                        method: 'POST',
                                        data: cmiForm
                                    })
                                    if (cmiResponse.status === 200) {
                                        // emptyShoppingCart()

                                        const { url, data } = cmiResponse.data
                                        const form = document.getElementById("offlineCmiPaymentForm") as HTMLFormElement
                                        form.method = "POST"
                                        form.action = url
                                        Object.keys(data).forEach((name) => {
                                            var input = document.createElement("input")
                                            input.name = name
                                            input.value = data[name]
                                            form.appendChild(input)
                                        })

                                        form.submit()

                                    } else {
                                        handleError()
                                    }
                                    // End CMI Request
                                }
                            } catch {
                                handleError()
                            }

                        } catch {
                            handleError()
                        }
                    }

                } else {
                    handleError()
                }
            } catch {
                handleError()
            }
        }
    })


    return (
        <Layout>
            <ReactHelmet title='Checkout Hors Ligne' />

            <div className="shopping-card d-flex align-items-center mt-2rem">
                <div className="container">
                    <form action="" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12 col-lg-7 checkout-card-items mb-4">
                                <div className="adresse-items">
                                    <div className="editform ox-hidden">
                                        <div className="row d-flex-start">
                                            <h4 className='text-start mb-4 mt-2 clr-blue' >Details de Commande</h4>
                                            <div className="col-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Nom et prenom"
                                                    spanValue="Nom et prenom"
                                                    name="fullName"
                                                    value={formik.values.fullName}
                                                    className={formik.touched.fullName && formik.errors.fullName ? "is-invalid" : ""}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                    isRequired
                                                />
                                            </div>

                                            <div className="col-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Email"
                                                    spanValue="Email"
                                                    name="email"
                                                    value={formik.values.email}
                                                    className={formik.touched.email && formik.errors.email ? "is-invalid" : ""}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                    isRequired
                                                />
                                            </div>

                                            <div className="col-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Telephone"
                                                    spanValue="Telephone"
                                                    name="telephone"
                                                    value={formik.values.telephone}
                                                    className={formik.touched.telephone && formik.errors.telephone ? "is-invalid" : ""}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                    isRequired
                                                />
                                            </div>

                                            <div className="col-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Adresse"
                                                    spanValue="Adresse"
                                                    name="adresse"
                                                    value={formik.values.adresse}
                                                    className={formik.touched.adresse && formik.errors.adresse ? "is-invalid" : ""}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                    isRequired
                                                />
                                            </div>

                                            <div className="col-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Ville"
                                                    spanValue="Ville"
                                                    name="ville"
                                                    value={formik.values.ville}
                                                    className={formik.touched.ville && formik.errors.ville ? "is-invalid" : ""}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                    isRequired
                                                />
                                            </div>

                                            <div className="col-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Code Postal"
                                                    spanValue="Code Postal"
                                                    name="codepostal"
                                                    value={formik.values.codepostal}
                                                    className={formik.touched.codepostal && formik.errors.codepostal ? "is-invalid" : ""}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                    isRequired
                                                />
                                            </div>

                                            <div className="col-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Remarque"
                                                    spanValue="Remarque"
                                                    name="orderNote"
                                                    textarea={true}
                                                    value={formik.values.orderNote}
                                                    className={formik.touched.orderNote && formik.errors.orderNote ? "is-invalid" : ""}
                                                    touched={formik.touched}
                                                    errors={formik.errors}
                                                    rows={6}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12 col-lg-5">
                                <div className="shopping-cart-checkout">

                                    <div className="row">
                                        <div className="cart-subtotal-title ff-varela-round fw-bold fs-5 mb-1">Articles de commande :</div>
                                        <hr className='hr-tag' />
                                        <div className="col-md-12 col-lg-12 checkout-items">
                                            <div className="checkout-items-centent">
                                                {cartItems.map(item => (
                                                    <OrderItem key={item.product} {...item} price={item.price} itemKey={Number(item.ukey)} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <hr className='hr-tag' />

                                        <div className="row">
                                            <div className="shopping-cart-checkout-total d-flex justify-content-between px-5 mb-5">
                                                <div className="shopping-cart-checkout-total-title px-">TOTAL</div>
                                                <div className="shopping-cart-checkout-total-value">{orderData.totalPrice.toFixed(2)} DH</div>
                                            </div>
                                            <div className="row d-flex justify-content-start">
                                                <div className="col-12 text-start">
                                                    {isLoading ?
                                                        <loadingHelper.DottedLoading />
                                                        :
                                                        <SubmitButton
                                                            className="mt-1 px-2rem py-14px"
                                                            btnLabel="Valider la commande"
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className='d-none'>
                <form name='offlineCmiPaymentForm' id="offlineCmiPaymentForm"></form>
            </div>
        </Layout>
    )
}

export default OfflineCheckout