import { useState, useEffect } from 'react';
import { ProductInfoProps } from '@helpers/Props';
import { notify } from '@helpers/Toastify';
import { apiUrl } from '@helpers/Helpers';
import useApiRequest from '@mbs-dev/api-request';

export const useProductInfo = (url: string): ProductInfoProps => {
  const { apiRequest } = useApiRequest();
  const [totalProducts, setTotalProducts] = useState(0);
  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);

  const getProductInfo = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/${url}`,
        method: 'GET',
      });

      if (response.status === 200) {
        setTotalProducts(response.data.total_products.totalProducts);
        setMinPrice(Number(response.data.total_products.minPrice));
        setMaxPrice(Number(response.data.total_products.maxPrice));
      }
    } catch (error) {
      notify(String(error))
    }
  };

  useEffect(() => {
    getProductInfo();
  }, [url]);

  return { minPrice, maxPrice, totalProducts };
};
