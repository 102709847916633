import { FC } from 'react';
import { ProductFetchProps } from '@helpers/Props';
import { ProductCard, emptyBox, hooks, loadingHelper } from '@helpers/Index';

const ProductFetch: FC<ProductFetchProps> = ({
    isLoadingData,
    isFetchingNextPage,
    filteredData,
    isGrid,
    totalProducts,
    isLoadingMoreData,
    windowWidth,
    fetchNextPage,
}) => {
    const { productIds, isProductIds } = hooks.useProductIds();

    return (
        <>
            {isLoadingData && !isFetchingNextPage && filteredData.length === 0 ? (
                <loadingHelper.AnimatedLoading label='des produits' isBoutique />
            ) : (
                <div className={`col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12 d-flex flex-wrap justify-content-${windowWidth < 768 ? 'around' : 'start'}`}>
                    {filteredData.length > 0 ?
                        filteredData.map((item) => (
                            <ProductCard key={item.id} item={item} isGrid={isGrid} productIdsObj={{ productIds, isProductIds }} />
                        ))
                        : !isLoadingData ?
                            <div className="col-12 mt-5 d-flex-center">
                                <img src={emptyBox} alt="empty box" className='shopping-cart-empty-box' />
                                <p className="clr-dark fw-600 ff-varela-round fs-4 mn-13">Aucun Produits</p>
                            </div>
                            : null
                    }

                    <div className="col-12 mt-4 d-flex justify-content-center">
                        {!isFetchingNextPage && filteredData.length < totalProducts && filteredData.length > 0 ?
                            <button className="btn-loading mt-3" onClick={() => fetchNextPage()}>
                                Chargement Plus
                            </button>
                            : null
                        }
                        {isLoadingMoreData && isFetchingNextPage ? <loadingHelper.LoadingWithDots /> : null}
                    </div>
                </div>
            )}
        </>
    );
};

export default ProductFetch;