import { aboutFamily, aboutKids, turkishAirlines } from "@helpers/Index";
import { OurOffre } from "@data/OurOffre";
import { OurOffreProps } from "@helpers/Props";
import { useWindowWidthContext } from "@contexts/WindowWidthContext";
import Layout from "@layouts/Layout";
import './AboutUs.css'

const AboutUs: React.FC = () => {

  const { windowWidth } = useWindowWidthContext()

  return (
    <Layout>
      <div className="container mt-2rem about-us mb-3rem">
        {/* <HeaderContainer className='fw-bold fs-1 mt-2' title="Apropos de nous" /> */}
        <div className="row mx-0">
          <div className="top-image o-hidden h-22rem w-100 radius-10px" >
            <span className="clr-light"></span>
            {/* <img src={aboutKids} alt="aboutFamily" className="w-100 h-100 object-fit-cover" /> */}
          </div>
        </div>

        <div className="row family-section mt-3rem">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h1 className="clr-purple mb-1rem">Bienvenue sur Rose Et Bleu - Où la Magie du Shopping Rencontre la Famille !</h1>

            <p className="clr-dark fs-22px line-height-40px">
              Chez Rose Et Bleu, nous sommes bien plus qu'une simple boutique en ligne. Nous sommes un espace dédié au bonheur familial, où chacun peut trouver quelque chose qui lui apporte joie, confort et style. Bienvenue dans notre univers merveilleux, où chaque achat devient une petite aventure à partager en famille !
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12  d-flex-end">
            <div className={`family-section-image ${windowWidth > 768 ? 'w-80 h-35rem' : 'w-100 h-25rem'} o-hidden radius-10px`}>
              <img src={aboutFamily} alt="aboutFamily" className="w-100 h-100 object-fit-cover" />
            </div>
          </div>
        </div>

        <hr className="hr-tag" />

        <div className="row kids-section mt-3rem">
          <div className="col-lg-6 col-md-6 col-sm-12  d-flex-start">
            <div className={`kids-section-image ${windowWidth > 768 ? 'w-80 h-35rem' : 'w-100 h-25rem'} o-hidden radius-10px`}>
              <img src={aboutKids} alt="aboutKids" className="w-100 h-100 object-fit-cover" />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <h1 className="clr-purple mb-1rem">Qui Nous Sommes?</h1>

            <p className="clr-dark fs-22px line-height-40px">
              Nous sommes une équipe passionnée, composée de parents, de créateurs et de professionnels de la mode, qui mettent tout en œuvre pour rendre chaque instant spécial. De la sélection des jouets les plus captivants aux vêtements les plus mignons pour les petits et les mamans stylées, nous sommes là pour vous inspirer et vous accompagner à chaque étape de votre voyage.
            </p>
          </div>
        </div>

        <hr className="hr-tag" />

        <div className="row airlines-section mt-3rem">
          <h3 className="clr-purple mb-1rem text-center">Trésors Turcs pour Petits Trésors</h3>
          <h5 className="clr-blue mb-1rem text-center">L'Enchantement de la Turquie pour vos Enfants !</h5>

          <div className="col-12 d-flex-center">
            <div className={`airlines-section-image  ${windowWidth > 768 ? 'w-80 h-35rem' : 'w-100 h-15rem'} o-hidden radius-10px`}>
              <img src={turkishAirlines} alt="turkishAirlines" className="w-100 h-100 object-fit-cover" />
            </div>
          </div>

          <div className="col-12 d-flex-center text-start mt-3rem">
            <p className={`clr-dark fs-22px line-height-40px  ${windowWidth > 768 ? 'w-90' : 'w-100'}`}>
              Découvrez notre sélection unique de produits pour enfants, venant tout droit des marchés animés de Turquie. Offrez à vos petits des jouets enchanteresses et des vêtements de qualité, inspirés par l'artisanat turc. Élevez leur monde avec l'authenticité et la magie de la Turquie, livrées chez vous avec amour.
            </p>
          </div>
        </div>

        <hr className="hr-tag" />

        <div className="row our-Offre-section d-flex-center  mt-3rem">
          <h1 className="clr-purple mb-1rem text-center mb-3rem">Ce Que Nous Offrons</h1>
          {OurOffre.map((offer: OurOffreProps) => (
            <div key={offer.key} className="col-lg-4 col-md-4 col-sm-12 h-17rem">
              <div className={`our-Offre-card ${windowWidth > 768 ? 'w-98' : 'w-100'} radius-10px shadow-1 h-100 position-relative c-pointer mb-1rem`}>
                <h1 className="text-center">{offer.icon}</h1>
                <h3 className="text-center clr-purple our-Offre-card-title">{offer.title}</h3>
                <p className="clr-dark fs-18px px-15px mt-1rem text-center our-Offre-card-desc">{offer.description}</p>

                <div className="our-Offre-card-overlay d-flex-center text-center radius-10px">
                </div>
                {/* <div className="our-Offre-section-overlay-before d-flex-center c-pointer text-center">
              </div> */}
              </div>


            </div>
          ))}
        </div>

      </div>
    </Layout>
  );
};

export default AboutUs;
