import React from 'react'
import Icons from '@helpers/IconsHelper'
import { ProductProps } from '@helpers/Props'
import ProductCard from '../ProductCard/ProductCard'
import { emptyBox } from '@helpers/Index'
import './SearchBox.css'

interface SearchBoxProps {
    searchBoxVisible: boolean
    textValue: string
    products: ProductProps[]
    productIds: number[]
    isProductIds: boolean
    position: string
    setTextValue: React.Dispatch<React.SetStateAction<string>>
    searchProducts: (text: string) => Promise<void>

}

const SearchBox: React.FC<SearchBoxProps> = ({
    searchBoxVisible,
    textValue,
    products,
    productIds,
    isProductIds,
    position,
    setTextValue,
    searchProducts,
}) => {
    return (
        <div className={`search-box ${position === 'nav' ? 'nav' : 'top'}-box ${searchBoxVisible ? 'show' : 'd-none'}`}>
            <div className="row">
                <div className="col-12">
                    <div className="input-group justify-content-end">
                        <input
                            type="text"
                            value={textValue}
                            className="form-controll search-box-input"
                            placeholder="Recherche de produits…"
                            onChange={(e) => {
                                setTextValue(e.target.value)
                                searchProducts(e.target.value)
                            }}
                        />
                        <div className="input-group-append">
                            <span className="input-group-textt">
                                <Icons.FiSearch className="search-box-icon" />
                            </span>
                        </div>
                    </div>
                </div>
                <hr className="hr-tag mt-3" />

                <div className="col-12 d-flex justify-content-center flex-wrap">
                    {products.length > 0 ? (
                        products.map((item) => (
                            <ProductCard key={item.id} item={item} productIdsObj={{ productIds, isProductIds }} />
                        ))
                    ) : textValue === '' ? (
                        ''
                    ) : (
                        <>
                            <div className="col-12 d-flex justify-content-center align-items-center col-">
                                <img src={emptyBox} alt="empty box" className="shopping-cart-empty-box" />
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center col-">
                                <div className="clr-dark fw-600 ff-varela-round fs-4 mn-13">Aucun Produits</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SearchBox