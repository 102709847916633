import React from 'react';
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
} from 'react-share';
import { IoMdShareAlt } from 'react-icons/io'
import { uploadUrl } from '@helpers/Helpers';
import './ShareButtons.css'

interface Product {
  slug: string;
  title: string;
  image?: string;
}

interface ShareButtonsProps {
  product: Product;
  entity: any;
}

const ShareButtons: React.FC<ShareButtonsProps> = ({ product, entity }) => {
  const itemUrl = `${process.env.REACT_APP_URL}/${entity}/${product.slug}`

  const wtspApi = 'https://api.whatsapp.com/send?text='


  return (
    <p className='d-flex align-items-center'>
      <span className='me-3 share-btn-text'>
        <IoMdShareAlt className='share-btn' />
        Partager:
      </span>

      {/* <WhatsappShareButton
        className='share-icon me-3'
        url={itemUrl}
        title={product.title}
      >
        <WhatsappIcon size={37} round />
      </WhatsappShareButton> */}

      <div className="shareon me-2 pe-1 share-icon">
        <a className="whatsapp radius-50" href={`${wtspApi}${itemUrl}`}></a>
      </div>

      <FacebookShareButton
        className='share-icon me-3'
        url={itemUrl}
        title={product.title}
      >
        <FacebookIcon size={37} round />
      </FacebookShareButton>

      {/* <FacebookMessengerShareButton
        url={itemUrl}
        appId='3443858605865162'
        className='share-icon me-3'
        title={product.title}
      >
        <FacebookMessengerIcon size={37} round />
      </FacebookMessengerShareButton> */}

      <TwitterShareButton
        className='share-icon me-3'
        url={itemUrl}
        title={product.title}
      >
        <TwitterIcon size={37} round />
      </TwitterShareButton>

      <PinterestShareButton
        url={itemUrl}
        media={`${uploadUrl}/${entity}_images/${product?.image}`}
        className='share-icon'
        title={product.title}
      >
        <PinterestIcon size={37} round />
      </PinterestShareButton>

      <span>&nbsp;</span>
    </p>
  );
};

export default ShareButtons;