import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import { HeaderContainerProps } from '@helpers/Props';
import './HeaderContainer.css'

const HeaderContainer: React.FC<HeaderContainerProps> = ({ title, titleTwo, className }) => {
  const { windowWidth } = useWindowWidthContext()

  return (
    <div className="roww align-items-center">
      <div className="col-md-12 col-lg-12 mb-1rem text-center justify-content-center">
        <div className="d-inline">
          <h2 className={`${windowWidth < 768 ? 'fs-1' : ''} ${className}`}><span className='clr-dark'>{title}</span> <span className='clr-pink ssecond-title'>{titleTwo}</span></h2>
        </div>
      </div>
    </div>
  );
};

export default HeaderContainer