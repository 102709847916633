export const OurOffre = [
    {
        key: 'O1',
      icon: "🎁",
      title: "Le bonheur à chaque âge",
      description: "Parcourez notre sélection soigneusement choisie de jouets captivants qui éveilleront l'imagination, de vêtements adorables qui feront sourire, et d'articles pour mamans qui allient style et praticité."
    },
    {
        key: 'O2',
      icon: "🛍️",
      title: "Un shopping simple & plaisant",
      description: "Parcourez notre sélection soigneusement choisie de jouets captivants qui éveilleront l'imagination, de vêtements adorables qui feront sourire, et d'articles pour mamans qui allient style et praticité."
    },
    {
        key: 'O3',
      icon: "🎉",
      title: "Offres spéciales & nouveautés",
      description: "Restez à l'affût des offres exclusives, des réductions irrésistibles et des nouvelles tendances qui ajoutent une touche de magie supplémentaire à chaque visite sur notre site."
    }
  ];