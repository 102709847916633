import { FC, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { hooks } from '@helpers/Index'
import { ProductProps } from '@helpers/Props'
import useApiRequest from '@mbs-dev/api-request';
import SearchBox from '../SearchBox/SearchBox'
import Icons from '@helpers/IconsHelper'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import { useShoppingCart } from '@contexts/ShoppingCartContext'
import './TopHeader.css'
import { apiUrl } from '@helpers/Helpers';

const TopHeader: FC = () => {
    const { windowWidth } = useWindowWidthContext()
    const { cartItems } = useShoppingCart()
    const cartItemsQuantity = cartItems.length
    const { apiRequest } = useApiRequest()
    const { productIds, isProductIds } = hooks.useProductIds()

    // States
    const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false)
    const [searchBoxVisible, setSearchBoxVisible] = useState(false)
    const [products, setProducts] = useState<ProductProps[]>([])
    const [textValue, setTextValue] = useState('')

    // Use Click Outside
    const elementRef = useRef<HTMLDivElement>(null)
    const blockRef = useRef<HTMLDivElement>(null)

    const handleShoppingCartBoxClose = () => {
        setShoppingCartBoxVisible(false)
    }

    hooks.useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef)

    const handleShoppingCartIconClick = () => {
        setShoppingCartBoxVisible(!ShoppingCartBoxVisible)
    }


    useEffect(() => {
        if (!searchBoxVisible) {
            setProducts([])
            setTextValue('')
        }
    }, [searchBoxVisible])

    const handleSearchIconClick = () => {
        setSearchBoxVisible(!searchBoxVisible)
    }

    const searchProducts = async (text: string) => {
        try {
            const response = await apiRequest({
                route: `${apiUrl}/products.json?title=${text}&disponibilite=enstock`,
                method: 'GET',
            })

            if (response.status === 200) {
                setProducts(response.data)
            } else {
            }

        } catch (error) {
        }
    }


    return (
        <div className='top-header-container d-flex justify-content-between align-items-center'>
            <div className="containerr top-container w-100 mx5">
                <div className="row align-items-center mx-3">
                    <div className={`col-lg-8 col-md-8 col-sm-8 col-8 align-items-center ${windowWidth < 770 ? 'textcenter' : 'text-start d-inline-flex ps-4'} ${windowWidth < 578 ? 'dcontents' : ''} `}>
                        <div className="info d-inline-flex m-info mb-1">
                            <Link to='tel:0658716457' className={`mbs-link clr-light fs-16px ff-varela-round  mt-sm-1 ${windowWidth < 578 ? 'me-2 mt-2' : 'me-4'}`}>
                                <Icons.ImPhone className='icon' /> 06 58 716 - 457
                            </Link>
                            <Link to='mailto:info@rose-bleu.ma' className={`mbs-link clr-light fs-16px ff-varela-round  mt-sm-1 ${windowWidth < 578 ? 'me-2 mt-2 d-none' : 'me-4'}`}>
                                <Icons.MdEmail className='icon' /> info@rose-bleu.ma
                            </Link>
                        </div>
                    </div>

                    <div className={`col-lg-4 col-md-4 col-sm-4 col-4 mt-2 align-items-center time`}>
                        <div className="">
                            <div className={`social-media-conten`}>
                                <div className="row"  >
                                    <div className="col-12 text-end d-flex-end">
                                        {/* {windowWidth > 992 ?
                                            <div className="me-3">
                                                <div onClick={handleSearchIconClick}>
                                                    <Icons.BsSearch className="top-header-icon me" />
                                                </div>
                                            </div>
                                            :
                                            null
                                        } */}
                                        <div className="me-3">
                                            <Link to='/login' className='nav-link'>
                                                <Icons.HiOutlineUserCircle className="top-header-icon me" />
                                            </Link>
                                        </div>

                                        <Link className="nav-link" to='/my-cart'>
                                            <Icons.MdShoppingCart className="top-header-icon" />
                                            <span className="position-absolute translate-middle badge card-badge rounded-circle z-index-9 bg-dark badge-card">
                                                {cartItemsQuantity}
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Search box */}
            <SearchBox
                searchBoxVisible={searchBoxVisible}
                textValue={textValue}
                products={products}
                productIds={productIds}
                isProductIds={isProductIds}
                position='top'
                setTextValue={setTextValue}
                searchProducts={searchProducts}
            />
            {/* End Search box */}
        </div>
    )
}

export default TopHeader