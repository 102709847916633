import { FC, useEffect } from 'react'
import './ProductsCategories.css'
import { useCategoriesContext } from '@contexts/CategoriesContext'
import ProductsCategoriesCard from '@components/ProductsCategoriesCard/ProductsCategoriesCard'
import { HeaderContainer } from '@helpers/Index'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import AOS from 'aos';
import { isEven } from '@mbs-dev/react-helpers'


const ProductsCategories: FC = () => {
    const { categories } = useCategoriesContext()
    const { windowWidth } = useWindowWidthContext()

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <>
            {categories.length > 0 ?
                <div className='product-categories container mt-20px'>
                    <HeaderContainer className={`fw-bold pt-${windowWidth > 768 ? '3' : '0'} fs-37px ff-outfit-semi`} title="NOS" titleTwo='CATÉGORIES' />
                    {categories.map((item) => (
                        <div key={item.id} data-aos="fade-up" data-aos-duration="3000">
                            <ProductsCategoriesCard key={item.id} categorie={item} />
                        </div>

                    ))}
                </div>
                :
                null
            }
        </>
    )
}

export default ProductsCategories
