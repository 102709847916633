import { FC, useEffect, useState } from 'react';
import useApiRequest from '@mbs-dev/api-request';
import { PackProps } from '@helpers/Props';
import { Link } from 'react-router-dom';
import HeaderContainer from '../HeaderContainer/HeaderContainer';
import PackSwiper from '@components/Carousel/PackSwiper/PackSwiper';
import { apiUrl } from '@helpers/Helpers';

const PopularePacks: FC = () => {
  const [packs, setPacks] = useState<PackProps[]>([]);
  const { apiRequest } = useApiRequest()

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/populare-packs/8`,
        method: 'GET',
      });
      setPacks(response.data)

    } catch (error) {

    }
  };
  useEffect(() => {
    fetchData();
  }, [])


  return (
    <>
      {
        packs.length > 0 ?
          <div className="productss pack-container mt-6">
            <div className="container">
              <HeaderContainer className="fw-bold mt-1 pt-1 fs-37px ff-outfit-semi" title="NOS" titleTwo='MEILLEURES PACKS' />
              <div className="row justify-content-center">
                <PackSwiper items={packs} />
              </div>
              <div className="d-none d-flex justify-content-center">
                <Link to='/boutique/packs' className="btn-loading mt-2 p-0 d-flex-center" >
                  explorer Les Packs
                </Link>
              </div>
            </div>
          </div>
          :
          ''
      }
    </>
  );
};

export default PopularePacks;