import { AiFillHome } from 'react-icons/ai';
import { BiPlus, BiHome } from 'react-icons/bi';
import {
    BsCheckCircleFill,
    BsChatRightDots,
    BsChatLeftText,
    BsCircle,
    BsDot,
    BsFacebook,
    BsFillTrashFill,
    BsGridFill,
    BsInstagram,
    BsPlusCircle,
    BsPlusCircleDotted,
    BsSearch,
    BsStars,
    BsTelephone
} from 'react-icons/bs';
import {
    CiCircleMore,
    CiEdit,
    CiFacebook,
    CiGrid2H,
} from 'react-icons/ci';
import {
    FaFacebookF,
    FaFilter,
    FaInstagram,
    FaLinkedinIn,
    FaShoppingBasket,
    FaTelegramPlane,
    FaYoutube,
} from 'react-icons/fa';
import { FaLocationDot, FaEarthAfrica } from 'react-icons/fa6'
import {
    FiLogOut,
    FiSearch,
    FiShoppingBag,
    FiShoppingCart,
} from 'react-icons/fi';
import {
    GrContact,
    GrContactInfo,
    GrFavorite,
    GrFormClose,
} from 'react-icons/gr';
import {
    HiMinusSm,
    HiOutlineMinusSm,
    HiOutlineUserCircle,
} from 'react-icons/hi';
import { ImPhone } from 'react-icons/im';
import { IoMdLocate } from 'react-icons/io';
import {
    MdContactMail,
    MdDelete,
    MdEmail,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineFavoriteBorder,
    MdOutlineHelpOutline,
    MdOutlineManageAccounts,
    MdOutlineRateReview,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp,
} from 'react-icons/md';
import { RiCoupon5Line, RiInstagramFill, RiMailOpenFill } from 'react-icons/ri';
import { RxDashboard } from 'react-icons/rx';

const Icons = {
    AiFillHome,
    BiPlus,
    BiHome,
    BsCheckCircleFill,
    BsChatRightDots,
    BsChatLeftText,
    BsCircle,
    BsDot,
    BsFacebook,
    BsFillTrashFill,
    BsGridFill,
    BsInstagram,
    BsPlusCircle,
    BsPlusCircleDotted,
    BsSearch,
    BsStars,
    BsTelephone,
    CiCircleMore,
    CiEdit,
    CiFacebook,
    CiGrid2H,
    FaFacebookF,
    FaFilter,
    FaInstagram,
    FaLinkedinIn,
    FaLocationDot,
    FaEarthAfrica,
    FaShoppingBasket,
    FaTelegramPlane,
    FaYoutube,
    FiLogOut,
    FiSearch,
    FiShoppingBag,
    FiShoppingCart,
    GrContact,
    GrContactInfo,
    GrFavorite,
    GrFormClose,
    HiMinusSm,
    HiOutlineMinusSm,
    HiOutlineUserCircle,
    ImPhone,
    IoMdLocate,
    MdContactMail,
    MdDelete,
    MdEmail,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineFavoriteBorder,
    MdOutlineHelpOutline,
    MdOutlineManageAccounts,
    MdOutlineRateReview,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp,
    RiCoupon5Line,
    RiInstagramFill,
    RiMailOpenFill,
    RxDashboard,
};

export default Icons;
